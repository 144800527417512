@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.project-list {
  overflow: hidden;
  .project-case-study {
    overflow: hidden;
    position: relative;
    margin-bottom: 40px;
    @include md {
      margin-bottom: 28px;
    }
    a {
      text-decoration: none;
    }
    .project-list-content {
      margin-bottom: 0px;
      padding: 0 !important;
    }
    .bg-blue {
      background-color: $primary-color;
    }
    .bg-midnight-slate {
      background-color: $midnight-slate-color;
    }

    .appLink {
      position: absolute;
      left: 45px;
      bottom: 12px;
      @include md {
        display: none;
      }
    }
  }
  .pagination-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 32px 0;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    transition: 0.3s;
    .previous-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        padding-right: 18px;
        padding-left: 18px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        span {
          svg {
            path {
              stroke: $minimal-color;
            }
          }
        }
      }
    }
    .next-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      float: inline-end;
      span {
        padding-right: 18px;
        padding-left: 18px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        span {
          svg {
            path {
              stroke: $minimal-color;
            }
          }
        }
      }
    }
  }
  .lazy-loader {
    padding: 8px 0 48px 0;
    text-align: center;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 30px;
    animation: l1 0.7s linear infinite alternate;
    color: $primary-color;
    &::before {
      content: "Loading...";
    }
  }
}

@keyframes l1 {
  to {
    opacity: 0;
  }
}

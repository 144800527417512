@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.our-team-hero {
  padding-bottom: 140px;
  @include md {
    padding-bottom: 72px;
  }
  .our-team-title {
    // font-family: $metropolis-light;
    // font-weight: 400;
    // margin: 30px 0 26px 0;
    // color: $primary-medium-color;
    // @include sm {
    //   font-size: 24px;
    //   margin-bottom: 22px;
    //   margin: 6px 0 10px 0;
    // }
    // br {
    //   @include rwd(425) {
    //     display: none;
    //   }
    // }

    color: #375f73;
    max-width: 1035px;
    margin: 12px auto 24px auto;
    font-weight: 500;
    text-align: center;
    font-size: 36px;
    line-height: normal;
    @include md {
      font-size: 24px;
      margin: 8px auto 16px auto;
      line-height: 24px;
      max-width: 100%;
    }
    @include sm {
      font-size: 18px;
      margin: 5px auto 8px auto;
      line-height: 18px;
      max-width: 100%;
    }
    br {
      @include rwd(375) {
        display: none;
      }
    }
  }
  .our-team-hero-content {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-right: 0;
    margin-bottom: 0;
    video {
      display: block;
      width: 1319px;
      height: auto;
      margin: 0 auto;
      outline: none;
      border: none;
      object-fit: contain;
      clip-path: inset(1px 1px);
    }
  }
}

@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.coreValues {
  margin-bottom: 140px;
  @include md {
    margin-bottom: 72px;
  }
  .container {
    h4 {
      color: $primary-color;
      text-align: center;
      margin-top: 0;
      margin-bottom: 40px;
      @include md {
        margin-bottom: 20px;
      }
    }
    .core-value {
      display: flex;
      .coreValues-container {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .coreValues-img {
          img {
            @include lg {
              width: 80px;
              height: 80px;
            }
            @include md {
              width: 54px;
              height: 54px;
            }
          }
        }
        p {
          color: $primary-color;
          font-family: $metropolis-regular;
          font-weight: 900;
          font-size: 34px;
          margin-top: 8px;
          margin-bottom: 0px;
          text-align: center;
          //   padding: 0px 10px 0px 10px;
          @include xl {
            font-size: 30px;
          }
          @include lg {
            font-size: 24px;
          }
          @include md {
            font-size: 20px;
          }
          @include sm {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.tech-partner {
  margin-bottom: 140px;
  @include md {
    margin-bottom: 72px;
  }
  h3 {
    color: $primary-color;
    text-align: center;
    margin-top: 0;
    margin-bottom: 40px;
    @include md {
      font-size: 24px;
      line-height: 26.11px;
      margin-bottom: 20px;
    }
    @include sm {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .tech-partner-container {
    position: relative;
    width: 100%;
    height: 428px;
    @include md {
      height: auto;
      display: flex;
      flex-flow: column;
    }
    .background-gradient {
      width: 100%;
      height: 100%;
      background: $primary-gradient;
      @include md {
        width: 100%;
        display: none;
      }
    }
    .tech {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include md {
        position: initial;
        background: $primary-gradient;
      }
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        @include md {
          width: 100%;
          flex-flow: column;
          padding-top: 16px;
          padding-bottom: 16px;
        }
        .left-img {
          width: 50%;
          height: 100%;
          @include md {
            width: 100%;
            margin-bottom: 20px;
          }
          img {
            width: 100%;
            height: calc(100% - 54px);
          }
        }
        .right-part {
          width: calc(50% - 40px);
          padding-left: 20px;
          padding-right: 20px;

          @include md {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
          }
          span {
            font-family: $metropolis-light;
            color: $primary-color;
            font-size: 40px;
            font-weight: 300;
            text-align: left;
            @include md {
              font-size: 24px;
              line-height: 24px;
            }
          }
          p {
            font-family: $metropolis-regular;
            color: $primary-medium-color;
            font-size: 20px;
            font-weight: 400;
            line-height: 36px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 0;
            @include md {
              margin-top: 6px;
              font-size: 14px;
              line-height: 21.6px;
            }
            @include sm {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@import "../../Assets/Scss/Variable";
@import "../../Assets/Scss/Mixins.scss";

.events-celebrations {
  padding-bottom: 140px;
  @include md {
    padding-bottom: 72px;
  }
  .events-celebrations-head {
    text-align: center;
    margin-bottom: 40px;
    @include md {
      margin-bottom: 20px;
    }
    h4 {
      margin: 0 0 10px 0;
      color: $primary-color;
      @include md {
        margin: 0 0 6px 0;
      }
    }
    p {
      font-size: 20px;
      margin: 0;
      color: $primary-medium-color;
      @include md {
        font-size: 14px;
      }
    }
  }
  .slider-container {
    position: relative;
    .slider-arrow {
      .arrow-btn {
        background: transparent;
        outline: none;
        border: none;
        padding: 0;
        cursor: pointer;
        img {
          height: 44px;
          min-height: 100%;
          @include md {
            height: 30px;
          }
          @include sm {
            height: 20px;
          }
        }
      }
    }
    .prev-btn {
      position: absolute;
      bottom: 10%;
      left: 30%;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      @include rwd(2560) {
        bottom: 17.5%;
        left: 30%;
      }
      @include rwd(1440) {
        bottom: 10.5%;
        left: 25%;
      }

      @include xl {
        bottom: 11%;
        left: 25%;
      }
      @include lg {
        bottom: 14%;
        left: 18%;
      }
      @include sm {
        left: 10%;
      }
    }
    .next-btn {
      position: absolute;
      bottom: 10%;
      right: 30%;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      @include rwd(2560) {
        bottom: 17.5%;
        right: 30%;
      }
      @include rwd(1440) {
        bottom: 10.5%;
        right: 30%;
      }
      @include xl {
        bottom: 11%;
        right: 25%;
      }
      @include lg {
        bottom: 14%;
        right: 18%;
      }
      @include sm {
        right: 10%;
      }
    }
    .bg-slide {
      .bg-img {
        img {
          max-width: 100%;
          width: 100%;
          min-height: 714px;
          object-fit: cover;
          @include lg {
            min-height: 600px;
          }
          @include md {
            min-height: 400px;
          }
          @include rwd(375) {
            min-height: 350px;
          }
        }
      }
    }
    .top-slide {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      .slick-slide {
        transform: scale(1);
        transition: 0.35s;
        opacity: 0.8;
      }
      .slick-center {
        transform: scale(1.4);
        opacity: 1;
        & + .slick-slide {
          .slider-wrap {
            .event-img {
              margin: 0 auto 0 0;
            }
          }
        }
        .slider-wrap {
          .event-img {
            margin: auto;
          }
          .event-title {
            bottom: 74px;
            opacity: 1;
            @include lg {
              bottom: 56px;
            }
          }
        }
      }
      .slider-wrap {
        padding: 120px 0;
        position: relative;
        @include lg {
          padding: 100px 0;
        }
        .event-img {
          max-width: 350px;
          margin: 0 0 0 auto;
          @include lg {
            max-width: 250px;
          }
          @include md {
            max-width: 150px;
          }
          @include rwd(375) {
            max-width: 130px;
          }
          img {
            max-width: 100%;
            width: 100%;
          }
        }
        .event-title {
          position: absolute;
          bottom: 100%;
          left: 0;
          width: 100%;
          transition: 0.35s;
          opacity: 0;
          z-index: -1;
          h2 {
            margin: 0;
            text-transform: uppercase;
            text-align: center;
            font-size: 34px;
            line-height: 38px;
            @include md {
              font-size: 24px;
            }
            @include rwd(375) {
              font-size: 18px;
            }
          }
          .filled-text {
            color: $minimal-color;
            margin-top: -40px;
            margin-left: 4px;
          }
          .border-text {
            color: transparent;
            -webkit-text-stroke: 0.08vw #fff;
          }
        }
      }
    }
  }
  .activity-title {
    font-size: 20px;
    text-align: center;
    margin: 40px 0 0 0;
    color: $primary-medium-color;
    @include md {
      font-size: 14px;
      margin: 20px 0 0 0;
    }
  }
}

.mobile-view-slider {
  .slick-slide {
    width: 350px !important;
  }
}

@import '../../Assets//Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.hero-text {
  padding: 120px 0 20px 0;
  @include md {
    padding: 60px 0 20px 0;
  }
  p {
    color: $primary-medium-color;
    font-size: 16px;
    line-height: 24px;
  }
}

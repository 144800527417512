@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.service-list-content {
  display: flex;
  align-items: center;
  position: relative;
  @include sm {
    flex-flow: column;
  }
  ul {
    width: 50%;
    padding: 0;
    margin: 0;
    &.desktop-view-content {
      @include sm {
        display: none;
      }
    }
    &.service-list-detail {
      position: relative;
      overflow: hidden;
      li {
        &.next {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: -1;
          @include sm {
            position: initial;
            z-index: 0;
          }
          .service-img {
            opacity: 0;
            visibility: visible;
            height: auto;
            transform: scale(0, 0);
            @include sm {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
        &.prev {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: -1;
          @include sm {
            position: initial;
          }
          .service-img {
            opacity: 0;
            visibility: visible;
            height: auto;
            transform: scale(1.1996, 1.1996);
          }
        }
      }
    }
    @include sm {
      width: 100%;
    }
    li {
      list-style: none;
      display: flex;
      @include sm {
        flex-flow: column;
        margin-bottom: 16px;
      }
      &.service-button {
        min-height: 94px;
        @include xl {
          min-height: 73px;
        }
        @include lg {
          min-height: 61px;
        }
        @include md {
          min-height: 54px;
        }
      }
      .mobile-view-btn {
        display: none;
        @include sm {
          display: flex;
        }
      }
      button {
        border: 0;
        background-color: transparent;
        padding: 24px;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: 1s ease;
        max-width: 50%;
        position: absolute;
        right: 0;
        @include xl {
          padding: 16px 24px;
        }
        @include lg {
          padding: 16px;
        }
        @include sm {
          position: initial;
          max-width: 100%;
          background-color: $primary-color;
          margin-top: -5px;
        }
        span {
          text-transform: uppercase;
          font-size: 35px;
          font-family: $lato;
          color: $primary-color;
          font-weight: 900;
          @include rwd(1399) {
            font-size: 30px;
          }
          @include xl {
            font-size: 24px;
          }
          @include lg {
            font-size: 18px;
          }
          @include md {
            font-size: 12px;
          }
          @include sm {
            font-size: 16px;
            color: $white-color;
          }
          @include rwd(375) {
            font-size: 14px;
          }
        }
        img {
          filter: brightness(0) invert(1);
          padding-right: 20px;
          transition: 1s ease;
          @include md {
            width: 28px;
          }
        }
      }
      &.active {
        button {
          background-color: $primary-color;
          max-width: 60%;
          @include sm {
            max-width: 100%;
          }
          span {
            color: $white-color;
          }
          img {
            padding-right: 0px;
          }
        }
        .service-img {
          opacity: 1;
          visibility: visible;
          overflow: initial;
          height: auto;
          transform: scale(1);
        }
      }
      .service-img {
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: 1s ease;
        transform: scale(0.5);
        @include sm {
          opacity: 1;
          visibility: visible;
          overflow: initial;
          height: auto;
          transform: scale(1);
          padding: 0 30px;
          width: calc(100% - 60px);
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.slider-container {
  .first-slider {
    width: 100%;
    margin-top: 40px;
    @include md {
      margin-top: 18px;
    }
    .img-logo {
      position: relative;
      height: 100%;
      width: calc(100% - 26px);
      margin-left: 22px;
      margin-right: 22px;
      outline: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include md {
        width: calc(100% - 24px);
        height: 100%;
        margin-left: 12px;
        margin-right: 12px;
      }
      @include sm {
        width: calc(100% - 20px);
        height: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }
      .background {
        width: 100%;
        position: absolute;
        height: 100%;
        background: $primary-color;
        opacity: 74%;
        z-index: 11;
      }

      .img-container {
        width: 100%;
        height: 100%;
        .img {
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
      .logo-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        @include md {
          width: calc(100% - 24px);
          height: 100%;
        }
        .logo {
          max-width: 100%;
          z-index: 1111;
        }
      }
      &:hover {
        .background {
          background: $primary-color;
          opacity: 92%;
        }
      }
    }
  }
  .second-slider {
    width: 100%;
    margin-top: 24px;
    @include md {
      margin-top: 15px;
    }
    .img-logo {
      position: relative;
      height: 100%;
      width: calc(100% - 26px);
      margin-left: 22px;
      margin-right: 22px;
      outline: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include md {
        width: calc(100% - 24px);
        height: 100%;
        margin-left: 12px;
        margin-right: 12px;
      }
      @include sm {
        width: calc(100% - 20px);
        height: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }
      .background {
        position: absolute;
        height: 100%;
        width: 100%;
        background: $primary-color;
        opacity: 74%;
        z-index: 111;
      }

      .img-container {
        width: 100%;
        height: 100%;
        .img {
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
      .logo-container {
        height: 100%;

        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
        @include md {
          width: calc(100% - 24px);
          height: 100%;
        }
        .logo {
          max-width: 100%;
          z-index: 1111;
        }
      }
      &:hover {
        .background {
          background: $primary-color;
          opacity: 92%;
        }
      }
    }
  }
}

@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.open-position {
  padding-bottom: 70px;
  font-family: Arial, sans-serif;
  @include md {
    padding-bottom: 50px;
  }
  .why-best-text {
    // font-family: $metropolis-regular;
    // font-weight: 400;
    // color: $primary-color;
    // margin: 40px 0 66px 0;
    color: #375f73;
    max-width: 1035px;
    margin: 12px auto 24px auto;
    font-weight: 500;
    text-align: center;
    font-size: 36px;
    line-height: normal;
    @include md {
      font-size: 24px;
      margin: 8px auto 16px auto;
      line-height: 24px;
      max-width: 100%;
    }
    @include sm {
      font-size: 18px;
      margin: 5px auto 8px auto;
      line-height: 18px;
      max-width: 100%;
    }
    br {
      @include rwd(375) {
        display: none;
      }
    }
  }
  .open-position-title {
    color: $primary-color;
    margin: 0 0 30px 0;
  }
  .open-position-accordion {
    .accordion-list {
      background-color: $gray-light-color;
      margin-bottom: 40px;
      @include md {
        margin-bottom: 24px;
      }
      .accordion-toggle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        border: 0;
        padding: 28px;
        outline: none;
        @include md {
          padding: 16px;
        }
        cursor: pointer;
        .position-title {
          text-align: left;
          b {
            color: $primary-medium-color;
            display: block;
            font-family: $metropolis-bold;
            font-size: 32px;

            margin-bottom: 12px;
            @include md {
              font-size: 24px;
              margin-bottom: 8px;
            }
            @include sm {
              font-size: 20px;
            }
          }
          span {
            color: $primary-medium-color;
            font-size: 20px;
            display: block;
            @include md {
              font-size: 16px;
            }
          }
        }
        svg {
          color: $primary-color;
          @include sm {
            width: 24px;
            height: 24px;
          }
        }
      }
      .position-details {
        overflow: hidden;
        transition: max-height 0.5s;
        .position-details-wrap {
          padding: 12px 28px 28px 28px;
          @include md {
            padding: 12px 16px 16px 16px;
          }
          h5 {
            font-family: "Lato", sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: normal;
            color: #103f57;
            margin: 16px 0 12px 0;
          }
          p {
            font-size: 16px;
            font-family: $metropolis-light;
            font-weight: 400;
            color: $primary-medium-color;
            line-height: 28px;
            margin: 0;
            @include sm {
              font-size: 12px;
              line-height: 20px;
            }
          }
          .content-title {
            margin: 52px 0 20px 0;
            color: $primary-color;
            @include md {
              margin: 36px 0 16px 0;
            }
          }
          ul {
            margin: 0 0 12px 0;

            li {
              font-size: 16px;
              font-family: $metropolis-light;
              font-weight: 400;
              color: $primary-medium-color;
              line-height: 28px;
              margin: 0 0 14px 0;

              @include md {
                margin: 0 0 10px 0;
              }
              @include sm {
                font-size: 12px;
                line-height: 20px;
                margin: 0 0 8px 0;
              }
              &:last-child {
                margin: 0;
              }
            }
          }
          .apply-btn {
            margin-top: 52px;
            @include sm {
              margin-top: 42px;
            }
            a {
              display: block;
              text-align: center;
              max-width: 245px;
              @include sm {
                max-width: 200px;
              }
            }
          }
        }
      }
      &.active {
        .position-details {
          transition: max-height 0.5s;
        }
      }
    }
  }
  .sub-title {
    text-align: center;
    color: $primary-color;
    margin: 0 0 12px 0;
    @include sm {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 6px 0;
    }
  }
  .sub-title-content {
    font-size: 20px;
    font-family: $metropolis-light;
    font-weight: 400;
    color: $primary-medium-color;
    line-height: 20px;
    margin: 0;
    text-align: center;
    @include md {
      font-size: 16px;
      line-height: 20px;
    }
    @include sm {
      font-size: 12px;
      line-height: 16px;
    }
  }

  p {
    margin: 0.5em 0;
  }

  h6 {
    margin-top: 1em;
    font-size: 1.1em;
  }

  ul,
  ol {
    padding-left: 1.5em;
    margin: 0.5em 0;
  }

  strong {
    font-weight: bold;
  }
}

@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.fullCareerContact {
  position: relative;
  height: 361px;
  display: flex;
  @include md {
    width: 100%;
    flex-flow: column;
    height: auto;
  }
  .background-gradient {
    width: 50%;
    height: 100%;
    background: $primary-gradient;
    @include md {
      width: 100%;
      display: none;
    }
  }
  .background-gary {
    width: 50%;
    height: 100%;
    background: $gray-color;
    @include md {
      width: 100%;
      display: none;
    }
  }
  .careerContent {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    @include md {
      position: initial;
    }
    .container {
      display: flex;
      height: 100%;
      .p-right {
        padding-right: 80px;
      }
      .p-left {
        padding-left: 80px;
      }
      @include md {
        width: 100%;
        flex-flow: column;
        padding: 0;

        .p-left {
          padding-left: 0px;
          background: $gray-color;
        }

        .p-right {
          padding-right: 0px;
          background: $primary-gradient;
        }
      }
    }
  }
  .career-contact {
    width: 50%;
    height: 100%;
    @include md {
      width: 100%;
    }

    .career-contact-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;

      button {
        text-decoration: none;
        background-color: transparent;
        border: none;
        padding: 0px;
        margin-bottom: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
          color: #103f57;
          font-size: 24px;
          font-family: $lato;
          font-weight: 900;
          line-height: 61px;
          letter-spacing: 1px;
          text-align: left;
          padding-right: 8px;
          transition: 0.3s;
          @include md {
            line-height: 30px;
          }
        }
        img {
          width: 24px;
          height: 13px;
        }
        &:hover {
          span {
            padding-right: 16px;
          }
        }
        @include md {
          padding-left: 28px;
          padding-right: 2px;
          margin-top: 37px;
        }
      }
      p {
        font-family: $metropolis-regular;
        font-size: 16px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: left;
        color: $primary-medium-color;
        margin: 0;
        @include md {
          padding-left: 28px;
          padding-right: 27px;
          line-height: 26px;
          margin-top: 8px;
          font-size: 14px;
          margin-bottom: 37px;
        }
      }
    }
  }
}

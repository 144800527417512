@import '../../Assets//Scss/Variable.scss';
@import '../../Assets//Scss/Mixins.scss';

.counter {
  margin-bottom: 140px;
  @include md {
    margin-bottom: 72px;
  }
  .container {
    display: flex;
    .counter-container {
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-right: 1px solid $primary-color;
      &:last-child {
        border: none;
      }
      .count {
        font-size: 84px;
        font-family: $metropolis-bold;
        color: transparent;
        -webkit-text-stroke: 0.18vw $minimal-color;
        @include lg {
          -webkit-text-stroke: 0.25vw $minimal-color;
          font-size: 70px;
        }
        @include md {
          -webkit-text-stroke: 0.28vw $minimal-color;
          font-size: 60px;
        }
        @include rwd(375) {
          display: block;
          -webkit-text-stroke: 0.2vw $minimal-color;
        }
        @include sm {
          -webkit-text-stroke: 0.3vw $minimal-color;
          font-size: 30px;
        }
      }
      p {
        color: $primary-color;
        font-family: $lato;
        font-weight: 900;
        font-size: 34px;
        margin-top: 0px;
        margin-bottom: 0px;
        text-align: center;
        padding: 0px 10px 0px 10px;
        @include xl {
          font-size: 30px;
        }
        @include lg {
          font-size: 24px;
        }
        @include md {
          font-size: 16px;
        }
        @include sm {
          font-size: 12px;
        }
      }
    }
  }
}

@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.client-review {
  background: $primary-gradient;
  padding: 66px 0 74px 0;
  margin-bottom: 80px;
  @include md {
    padding: 18px 0 30px 0;
    margin-bottom: 72px;
  }
  .client-review-title {
    h4 {
      color: $primary-color;
      margin: 0 0 10px 0;
      text-align: center;
    }
    p {
      color: $primary-medium-color;
      font-weight: 500;
      text-align: center;
      margin: 0 0 48px 0;
      @include md {
        margin: 0 0 24px 0;
        font-size: 14px;
      }
    }
  }
  .client-review-slider-section {
    display: flex;
    align-items: flex-end;
    & > .slider-arrow {
      width: 216px;
      height: 134px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-color;
      margin-bottom: 3px;
      @include xl {
        width: 150px;
      }
      @include lg {
        width: 100px;
        height: 100px;
      }
      @include md {
        display: none;
      }
      &.next-btn {
        margin-left: -1px;
      }
      button {
        background: transparent;
        border: 0;
        cursor: pointer;
        outline: none;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
    .client-review-slider {
      width: calc(100% - 432px);
      @include xl {
        width: calc(100% - 300px);
      }
      @include lg {
        width: calc(100% - 200px);
      }
      @include md {
        width: 100%;
      }
      .slick-dots {
        li {
          &.slick-active {
            width: 48px;
            button {
              width: 100%;
              &::before {
                width: 100%;
                border-radius: 8px;
                background-color: $primary-color;
                transition: 0.4s;
              }
            }
          }
          button {
            &::before {
              content: "";
              opacity: 1;
              border-radius: 50%;
              width: 12px;
              height: 12px;
              border: 1px solid $primary-color;
            }
          }
        }
      }
      .slick-slide {
        &.slick-active {
          .slider-content {
            overflow: hidden !important;
            .slider-img-part {
              .client-img {
                opacity: 1;
                transform: translateY(0);
              }
            }
            .client-review-content {
              h2 {
                transform: translateY(0);
              }
              h4 {
                transform: translateY(0);
              }
              .review-text {
                p {
                  transform: translateY(0);
                }
              }
            }
          }
        }
        .slider-content {
          overflow: hidden !important;
          display: flex;
          align-items: flex-end;
          min-height: 524px;
          @include rwd(1200) {
            min-height: 552px;
          }
          @include xl {
            min-height: 596px;
          }
          @include md {
            flex-flow: column;
          }
          .slider-img-part {
            width: 40%;
            position: relative;
            @include md {
              width: 100%;
              display: flex;
              align-items: flex-end;
            }
            & > .slider-arrow {
              display: none;
              @include md {
                display: block;
                min-width: calc(50% - 150px);
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary-color;
              }
              @include sm {
                min-width: calc(50% - 100px);
              }
              button {
                background: transparent;
                border: 0;
                cursor: pointer;
                outline: none;
                img {
                  filter: brightness(0) invert(1);
                  @include sm {
                    max-width: 30px;
                  }
                  @include rwd(375) {
                    max-width: 20px;
                  }
                }
              }
            }
            .client-img {
              max-width: 100%;
              position: absolute;
              bottom: auto;
              transition: 1s;
              left: 0;
              bottom: 0;
              opacity: 0;
              transform: translateY(100%);
              @include md {
                width: 300px;
                position: initial;
              }
              @include sm {
                width: 200px;
              }
            }
            .project-logo {
              position: absolute;
              left: 0;
              bottom: 0;
              height: 134px;
              width: 100%;
              background-color: rgba($primary-slate-color, 0.5);
              display: flex;
              align-items: center;
              justify-content: center;
              @include lg {
                height: 100px;
              }
              @include md {
                width: 300px;
                left: 50%;
                transform: translateX(-50%);
                height: 80px;
              }
              @include sm {
                width: 200px;
              }
              img {
                max-width: 90%;
                max-height: 120px;
                @include lg {
                  max-height: 80px;
                }
                @include md {
                  display: none;
                }
              }
            }
          }
          .client-review-content {
            width: 60%;
            @include md {
              width: 100%;
              position: relative;
              z-index: 1;
            }
            h2 {
              margin: 0 0 4px 0;
              color: $primary-color;
              padding-left: 24px;
              transition: 0.6s;
              transform: translateY(20px);
              @include md {
                position: relative;
                max-width: 300px;
                margin: -75px auto 0;
                color: $white-color;
              }
              @include sm {
                font-size: 20px;
                max-width: 200px;
              }
            }
            h4 {
              margin: 0 0 42px 0;
              padding-left: 24px;
              color: $primary-color;
              font-family: $metropolis-light;
              transition: 1s;
              transform: translateY(20px);
              @include xl {
                margin: 0 0 28px 0;
              }
              @include md {
                position: relative;
                max-width: 300px;
                margin: 0px auto 12px;
                color: $white-color;
                font-size: 16px;
              }
              @include sm {
                max-width: 200px;
              }
            }
            .review-text {
              min-height: 226px;
              background-color: $midnight-slate-color;
              padding: 24px;
              @include lg {
                padding: 16px;
                min-height: 275px;
              }
              @include md {
                min-height: 175px;
              }
              @include sm {
                min-height: 200px;
              }
              @include rwd(425) {
                min-height: 250px;
              }
              p {
                color: $white-color;
                font-family: $metropolis-light;
                font-size: 16px;
                line-height: 28.8px;
                text-align: left;
                transition: 1.4s;
                transform: translateY(20px);
                @include lg {
                  margin: 16px 0 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .review-view-all-btn {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 40px auto 0 auto;
    @include md {
      margin: 8px auto 0 auto;
    }
    div {
      width: 43%;
      @include xl {
        width: 41%;
      }
      @include lg {
        width: 39%;
      }
      @include md {
        width: 32%;
      }
      @include sm {
        width: 20%;
      }
    }
    button {
      text-decoration: none;
      background-color: transparent;
      border: none;
      padding: 0px;
      cursor: pointer;
      display: flex;
      align-items: center;
      span {
        color: #103f57;
        font-size: 24px;
        font-family: $lato;
        font-weight: 900;
        line-height: 61px;
        letter-spacing: 1px;
        text-align: left;
        padding-right: 8px;
        transition: 0.3s;
        @include md {
          line-height: 30px;
        }
      }
      img {
        width: 24px;
        height: 13px;
      }
      &:hover {
        span {
          padding-right: 16px;
        }
      }
      @include md {
        padding-left: 28px;
        padding-right: 2px;
        margin-top: 37px;
      }
    }
  }
}

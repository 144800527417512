@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.trusted-partner-wrap {
  padding-bottom: 100px;
  @include md {
    padding-bottom: 60px;
  }
  text-align: center;
  h3 {
    color: $primary-color;
    margin: 0 0 50px 0;
    @include md {
      font-size: 26px;
      line-height: 34px;
    }
    @include sm {
      font-size: 24px;
      line-height: 32px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    max-width: 780px;
    gap: 16px;
    margin: 0 auto 40px;
    @include lg {
      max-width: 650px;
    }
    @include md {
      max-width: 450px;
      margin: 0 auto 24px;
    }
    @include sm {
      max-width: 280px;
      margin: 0 auto 14px;
    }
    li {
      list-style: none;
      @include lg {
        max-width: 50px;
      }
      @include md {
        max-width: 40px;
      }
      @include sm {
        max-width: 32px;
      }
      img {
        max-width: 100%;
        width: 80px;
      }
    }
  }
}

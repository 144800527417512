$primary-color: #103f57;
$primary-semi-color: #174f6b;
$minimal-color: #fac212;
$white-color: #ffffff;
$black-color: #000000;
$red-color: #ff0000;
$primary-light-color: #e2eff5;
$primary-medium-color: #375f73;
$gray-color: #f3f3f3;
$gray-light-color: #f7f7f7;
$border-color: #cdcdcd;
$primary-slate-color: #235b77;
$midnight-slate-color: #343446;
$midnight-slate-light-color: #464660;
$midnight-slate-dark-color: #5b5b5b;
$primary-gradient: linear-gradient(77.16deg, #cfdcfa 0%, #d5d7f2 51%, #b9e0f7 100%);
$metropolis-regular: 'Metropolis-Regular';
$metropolis-light: 'Metropolis-Light';
$metropolis-medium: 'Metropolis-Medium';
$metropolis-semiBold: 'Metropolis-SemiBold';
$metropolis-bold: 'Metropolis-Bold';
$lato: 'Lato', sans-serif;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.heroCaseStudy {
  .case-study {
    .hero-case-study {
      width: 100%;
      text-align: center;
      .bg-primary {
        width: 100%;
        height: 506px;
        @include sm {
          height: 450px;
        }
      }
      .bg-blue {
        background-color: $primary-color;
      }
      .bg-midnight-slate {
        background-color: $midnight-slate-color;
      }
      .hero-content {
        .hero-project-details {
          padding-top: 86px;
          @include md {
            padding-top: 50px !important;
          }
          .content {
            margin-bottom: 26px;
            h3 {
              display: inline-block;
              color: $white-color;
              margin: 0;
              text-align: center;
              line-height: 40.8px;
            }
            span {
              color: $white-color;
              font-family: $metropolis-regular;
              font-size: 36px;
              font-weight: 400;
              line-height: 64.8px;
              text-align: left;
              @include lg {
                font-size: 30px;
                line-height: 38px;
              }
              @include md {
                font-size: 20px;
                line-height: 30px;
              }
            }
          }
        }
        .industry-container {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          text-align: center;
          .industry {
            padding: 0 5px;
            h5 {
              font-size: 20px;
              color: $white-color;
              margin-bottom: 10px;
              margin-top: 0;
            }
            p {
              text-align: center;
              color: $white-color;
              font-family: $metropolis-regular;
              font-size: 16px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 1px;
              text-align: left;
              margin: 0;
              @include md {
                text-align: center;
                font-size: 14px;
                line-height: 14px;
              }
            }
          }
        }
      }
      .hero-img {
        width: 100%;
        margin-top: -270px;
        margin-bottom: 140px;
        @include md {
          margin-bottom: 72px;
        }
        @include sm {
          margin-top: -200px;
        }
        .img {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
            margin-top: 74px;
            max-height: 862px;
            @include md {
              margin-top: 30px;
            }
          }
        }
      }
    }
    .case-study-content {
      margin-bottom: 140px;
      @include md {
        margin-bottom: 72px;
      }
      .project-content {
        display: flex;
        @include md {
          flex-direction: column;
        }
        .category-container {
          width: 50%;
          @include md {
            width: 100%;
          }
          .category {
            margin-bottom: 40px;
            border-left: 2px solid $minimal-color;
            padding-left: 10px;
            margin-left: 50px;
            @include md {
              margin-bottom: 32px;
              margin-left: 25px;
            }
            span {
              color: $primary-color;
              font-family: $lato;
              font-size: 26px;
              font-weight: 900;
              line-height: 28.29px;
              letter-spacing: 1px;
              @include md {
                font-size: 24px;
                line-height: 26.11px;
              }
            }
            p {
              color: $primary-color;
              font-family: $metropolis-regular;
              font-size: 18px;
              font-weight: 400;
              line-height: 18px;
              text-align: left;
              margin-bottom: 0;
              margin-top: 12px;
              @include md {
                font-size: 14px;
                line-height: 21.6px;
                margin-top: 8px;
              }
            }
            &:last-child {
              margin-bottom: 0;
              @include md {
                margin-bottom: 20px;
              }
            }
          }
        }
        .project-details {
          width: 50%;
          @include md {
            width: 100%;
            margin-top: 41px;
          }
          span {
            color: $primary-color;
            font-family: $lato;
            font-size: 26px;
            font-weight: 900;
            line-height: 28.29px;
            letter-spacing: 1px;
            @include md {
              font-size: 24px;
              line-height: 26.11px;
            }
          }
          p {
            color: $primary-color;
            font-family: $metropolis-regular;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            margin-bottom: 0;
            margin-top: 20px;
            @include md {
              font-size: 14px;
              line-height: 21.6px;
            }
          }
        }
      }
    }
    .case-study-details {
      margin-bottom: 140px;
      @include md {
        margin-bottom: 72px;
      }
      .challenges {
        margin-bottom: 48px;
        @include md {
          margin-bottom: 30px;
        }
        span {
          color: $primary-color;
          font-family: $lato;
          font-size: 26px;
          font-weight: 900;
          line-height: 28.29px;
          letter-spacing: 1px;
          @include md {
            font-size: 24px;
            line-height: 26.11px;
          }
        }
        p {
          color: $primary-color;
          font-family: $metropolis-regular;
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          text-align: left;
          margin-bottom: 0;
          margin-top: 18px;
          @include md {
            font-size: 14px;
            line-height: 21.6px;
            margin-top: 12px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .case-study-slider {
      margin-bottom: 140px;
      @include md {
        margin-bottom: 72px;
      }
      .project-screenshort-slider {
        padding: 0 25px;
        @include md {
          padding: 0 12.5px;
        }
        .img-slider {
          .img-screenshort {
            margin: 15px 15px;
            img {
              width: 100%;
              box-shadow: 2px 2px 10px 4px rgba(71, 71, 71, 0.05),
                -2px -2px 10px 4px rgba(71, 71, 71, 0.05);
            }
          }
        }
        .slick-prev {
          left: 0;
          width: 48px;
          height: auto;
          z-index: 1;
          @include md {
            width: 24px;
          }
        }
        .slick-next {
          right: 0;
          width: 48px;
          height: auto;
          @include md {
            width: 24px;
          }
        }
        .slick-list {
          padding: 0px 0px !important;
          .slick-slide {
            img {
              max-width: 100%;
            }
          }
        }

        .mobile-view-img {
          @include rwd(4000) {
            height: 624px;
          }
          @include rwd(1440) {
            height: 550px;
          }
          @include xl {
            height: 500px;
          }
          @include lg {
            height: 400px;
          }
          @include md {
            height: 300px;
          }
        }
      }
    }
  }
}

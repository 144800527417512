@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.our-services {
  width: calc(50% - 120px);
  padding-top: 120px;
  padding-bottom: 120px;
  padding-right: 120px;
  @include lg {
    width: calc(100% - 40px);
    padding: 32px 40px 32px 0px;
  }
  @include md {
    width: calc(100% - 92px);
    padding: 32px 76px 32px 16px;
    background: $gray-color;
  }
  h4 {
    color: $primary-color;
    margin-top: 0px;
    margin-bottom: 14px;
  }
  p {
    font-family: $metropolis-light;
    color: $primary-medium-color;
    font-size: 30px;
    font-weight: 300;
    line-height: 45.6px;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 54px;
    @include md {
      font-size: 18px;
      margin-bottom: 16px;
      line-height: 32.4px;
    }
  }
  .get-started {
    width: 50%;
    @include md {
      width: 40%;
    }
    @include sm {
      width: 70%;
    }
    .primary-btn {
      width: 100%;
      @include lg {
        span {
          font-size: 18px;
        }
      }
      &:hover {
        span {
          color: $primary-color;
          border: 1px solid $primary-color;
        }
      }
    }
  }
}

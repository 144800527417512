@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.container {
  .contact {
    width: 100%;
    display: flex;
    margin-bottom: 140px;
    @include md {
      margin-bottom: 72px;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

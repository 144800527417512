@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.contact-details {
  .container {
    h1 {
      color: $primary-color;
      text-align: center;
      margin-top: 120px;
      margin-bottom: 40px;
      @include md {
        margin-bottom: 20px;
        margin-top: 60px;
      }
    }
    .text {
      color: $primary-color;
      font-family: $metropolis-regular;
      font-size: 30px;
      font-weight: 300;
      line-height: 45.6px;
      text-align: left;
      margin-bottom: 12px;
      display: none;
      @include md {
        display: block;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
      }
    }
    .contact-detail {
      display: flex;
      @include md {
        flex-flow: column;
      }
      .contact-forms {
        width: 40%;
        .contact-form {
          width: calc(100% - 82px);
        }
        @include lg {
          width: 50%;
          .contact-form {
            width: calc(100% - 40px);
          }
        }
        @include md {
          width: 100%;
          .contact-form {
            width: 70%;
            margin: 0 auto;
          }
        }
        @include sm {
          width: 100%;
          .contact-form {
            width: calc(100% - 40px);
            margin: 0 auto;
          }
        }
      }
      .contact-info-container {
        width: 60%;
        padding-top: 40px;
        padding-left: 34px;
        @include lg {
          width: 50%;
          padding-left: 16px;
        }
        @include md {
          width: 100%;
          padding-left: 0;
        }
        @include sm {
          width: 100%;
        }
        .contact-text {
          color: $primary-color;
          font-family: $metropolis-regular;
          font-size: 30px;
          font-weight: 300;
          line-height: 45.6px;
          text-align: left;
          display: block;
          @include lg {
            font-size: 24px;
            line-height: 40px;
          }
          @include md {
            display: none;
            font-size: 18px;
            line-height: 27px;
          }
        }
        .contact-info-details {
          display: flex;
          margin-top: 40px;
          @include sm {
            flex-flow: column;
            margin-top: 0;
          }
          .location {
            width: 50%;
            @include sm {
              width: 100%;
              display: flex;
            }
            .address {
              width: 100%;
              @include sm {
                width: 100%;
              }
              .location-title {
                h6 {
                  color: $primary-color;
                  margin-top: 0;
                  margin-bottom: 16px;
                }
                img {
                  @include sm {
                    margin-bottom: 8px;
                  }
                }
              }
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  margin-bottom: 30px;
                  @include sm {
                    margin-bottom: 18px;
                  }
                  h6 {
                    color: $primary-color;
                    margin-top: 8px;
                    margin-bottom: 6px;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    gap: 10px;
                    span {
                      display: flex;
                      svg {
                        height: 26px;
                        width: 26px;
                      }
                    }
                    @include sm {
                      margin-top: 0;
                    }
                  }
                  p {
                    color: $primary-color;
                    font-family: $metropolis-light;
                    line-height: 24px;
                    margin: 0;
                    @include xl {
                      font-size: 14px;
                      line-height: 21px;
                    }
                    @include sm {
                      font-size: 14px;
                      line-height: 21px;
                    }
                  }
                }
              }
            }
          }
          .contact-lists {
            width: 50%;
            padding-left: 52px;
            @include lg {
              width: calc(50% - 12px);
              padding-left: 12px;
            }
            @include md {
              padding-left: 52px;
            }
            @include sm {
              width: 100%;
              padding-left: 0px;
            }
            .contact-list {
              margin-bottom: 36px;
              @include sm {
                margin-bottom: 18px;
              }
              h6 {
                margin-top: 0;
                margin-bottom: 6px;
                color: $primary-color;
                @include sm {
                  margin-bottom: 4px;
                }
              }
              .contact-list-wrap {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                span {
                  width: 20px;
                  height: 20px;
                  line-height: 0px;
                  margin-right: 12px;
                  @include sm {
                    margin-right: 4px;
                  }
                  svg {
                    fill: $minimal-color;
                    width: 100%;
                    height: 100%;
                  }
                }
                a {
                  color: $primary-color;
                  text-decoration: none;
                  @include lg {
                    font-size: 14px;
                  }
                }
              }
              &:last-child {
                .contact-list-wrap {
                  p {
                    margin-top: 0;
                    margin-bottom: 0;
                    color: $primary-color;
                    
                    @include lg {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.about-details {
  margin-bottom: 140px;
  @include md {
    margin-bottom: 72px;
  }
  .container {
    .hero-img {
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content {
      p {
        color: $primary-color;
        font-family: $metropolis-regular;
        font-size: 24px;
        font-weight: 400;
        line-height: 43.2px;
        text-align: center;
        margin-bottom: 0px;
        margin-top: 0px;
        @include lg {
          font-size: 18px;
          line-height: 32px;
        }
        @include md {
          font-size: 14px;
          line-height: 21.6px;
        }
        @include sm {
          font-size: 12px;
          line-height: 21.6px;
        }
        span {
          font-family: $metropolis-bold;
          font-weight: 600;
        }
      }
    }
  }
}

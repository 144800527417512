@import '../../Assets/Scss/Variable';
@import '../../Assets/Scss/Mixins.scss';

.header-transparent {
  background-color: transparent;
  transition: 0.35s ease-out;
  .header-wrapper {
    .toggle-icon {
      .hamburger-toggle {
        &.close-btn {
          .hamburger-icon {
            background-color: $primary-color;
            &::after {
              background-color: $primary-color;
            }
          }
        }
        .hamburger-icon {
          background-color: $primary-color;
          &::before {
            background-color: $primary-color;
          }
          &::after {
            background-color: $primary-color;
          }
        }
      }
    }
    .header-sub-wrap {
      .header-menu {
        ul {
          &.menu-list {
            & > li {
              & > button {
                color: $primary-color;
                &::before {
                  background: $primary-color;
                }
              }
            }
          }
        }
      }
    }
    .connect-btn {
      button {
        width: 0;
        overflow: hidden;
      }
    }
  }
}
.header-blue {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: $primary-color;
  animation: slideDown 0.35s ease-out;
  .header-wrapper {
    .toggle-icon {
      .hamburger-toggle {
        &.close-btn {
          .hamburger-icon {
            background-color: $white-color;
            &::after {
              background-color: $white-color;
            }
          }
        }
        .hamburger-icon {
          background-color: $white-color;
          &::before {
            background-color: $white-color;
          }
          &::after {
            background-color: $white-color;
          }
        }
      }
    }
    .header-sub-wrap {
      .header-menu {
        ul {
          &.menu-list {
            & > li {
              & > button {
                color: $white-color;
                &::before {
                  background: $white-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
.header-wrapper {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .toggle-icon {
    display: none;
    @include md {
      display: block;
    }
    .hamburger-toggle {
      display: block;
      position: relative;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: none;
      background-color: transparent;
      outline: none;
      &.close-btn {
        .hamburger-icon {
          height: 3px;
          width: 24px;
          left: 5px;
          top: calc(50% - 2px);
          transform: rotate(-45deg);
          &::after {
            width: 24px;
            height: 3px;
            top: 0px;
            transform: rotate(-270deg);
          }
          &::before {
            transform: scale(0);
          }
        }
      }
      .hamburger-icon {
        position: absolute;
        width: 24px;
        height: 3px;
        border-radius: 4px;
        top: calc(50% - 2px);
        left: calc(50% - 15px);
        transition: all 0.3s linear;
        &::before {
          content: '';
          left: 0;
          bottom: 8px;
          position: absolute;
          width: 24px;
          height: 3px;
          border-radius: 4px;
        }
        &::after {
          content: '';
          left: 0;
          top: 8px;
          position: absolute;
          width: 24px;
          height: 3px;
          border-radius: 4px;
          transition: all 0.3s linear;
        }
      }
    }
  }
  .header-sub-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    @include md {
      justify-content: center;
    }
    .header-logo {
      max-width: 230px;
      min-width: 230px;
      @include lg {
        max-width: 160px;
        min-width: 160px;
      }
      img {
        width: 100%;
      }
    }
    .header-menu {
      width: 100%;
      @include md {
        display: none;
      }
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        &.menu-list {
          display: flex;
          justify-content: space-between;
          padding: 0 66px;
          max-width: 600px;
          @include xl {
            padding: 0 24px;
          }
          @include lg {
            padding: 0 12px;
            max-width: 465px;
            margin: auto;
          }
          @include rwd(800) {
            max-width: 405px;
          }
          & > li {
            & > button {
              background-color: transparent;
              border: 0;
              padding: 17px 10px;
              font-family: $metropolis-semiBold;
              font-size: 16px;
              display: flex;
              align-items: center;
              gap: 4px;
              position: relative;
              cursor: pointer;
              &.active-menu {
                &::before {
                  transform-origin: 0 50%;
                  transform: scale3d(1, 1, 1);
                }
              }
              & > span {
                @include xl {
                  letter-spacing: 0px;
                }
                @include lg {
                  font-size: 14px;
                }
              }
              svg {
                transition: 0.35s;
              }
              @include lg {
                padding: 10px 6px;
                gap: 2px;
                font-size: 15px;
              }
              &:hover {
                &::before {
                  transform-origin: 0 50%;
                  transform: scale3d(1, 1, 1);
                }
                svg {
                  transform: rotate(180deg);
                }
              }
              &::before {
                content: '';
                width: 100%;
                height: 2px;
                position: absolute;
                left: 0;
                bottom: 0;
                background: #fff;
                transition: 300ms transform ease;
                transform: scale3d(0, 1, 1);
                background: $primary-color;
                transform-origin: 100% 50%;
              }
            }
            &:hover {
              .submenu {
                opacity: 1;
                top: calc(100% - 16px);
                visibility: visible;
                z-index: 1;
              }
            }
            .submenu {
              position: absolute;
              left: 0;
              top: 75%;
              width: 100%;
              opacity: 0;
              visibility: hidden;
              z-index: -1;
              -webkit-transition: all 0.25s ease;
              -moz-transition: all 0.25s ease;
              -ms-transition: all 0.25s ease;
              -o-transition: all 0.25s ease;
              transition: all 0.25s ease;
              .submenu-content {
                border-radius: 18px;
                background-color: $white-color;
                box-shadow: 0px 1px 4px 0px #00000033;
                margin-top: 16px;
              }
              .submenu-wrap {
                padding: 52px;
                display: flex;
                align-items: center;
                gap: 48px;
                @include lg {
                  padding: 16px;
                  gap: 16px;
                }
                .submenu-details {
                  width: 35%;
                  h4 {
                    font-family: $metropolis-bold;
                    color: $primary-color;
                    margin: 0 0 6px 0;
                  }
                  p {
                    font-family: $metropolis-regular;
                    color: $primary-medium-color;
                    margin: 0;
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
                .submenu-list {
                  width: 65%;
                  .submenu-list-wrap {
                    display: flex;
                    gap: 48px;
                    @include lg {
                      gap: 16px;
                    }
                    ul {
                      width: 50%;
                      li {
                        button {
                          margin-bottom: 10px;
                          padding: 5px 10px;
                          display: flex;
                          align-items: center;
                          color: $primary-color;
                          border-radius: 4px;
                          transition: 0.35s;
                          cursor: pointer;
                          border: 0;
                          background-color: transparent;
                          width: calc(100% - 20px);
                          font-size: 16px;
                          &:hover {
                            background-color: $primary-light-color;
                          }
                          svg {
                            color: $primary-color;
                            width: 24px;
                            height: 24px;
                            min-width: 24px;
                            path {
                              fill: $primary-color;
                            }
                          }
                          span {
                            margin-left: 12px;
                            font-family: $metropolis-light;
                            text-align: left;
                          }
                        }
                      }
                    }
                  }
                  .view-all-btn {
                    margin-top: 30px;
                    @include lg {
                      margin-top: 6px;
                    }
                    button {
                      background-color: transparent;
                      border: 0;
                      padding: 0;
                      padding: 5px 10px;
                      font-size: 18px;
                      font-family: $metropolis-semiBold;
                      color: $primary-color;
                      cursor: pointer;
                      position: relative;
                      span {
                        transition: 0.35s;
                        padding-right: 4px;
                      }
                      img {
                        width: 24px;
                        height: 13px;
                      }
                      &:hover {
                        span {
                          padding-right: 12px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .connect-btn {
    min-width: 255px;
    @include xl {
      min-width: 170px;
    }
    @include lg {
      min-width: 120px;
    }
    @include md {
      min-width: 40px;
    }
    .primary-btn {
      width: 100%;
      @include md {
        display: none;
      }
      span {
        white-space: nowrap;
      }
    }
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.mobile-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: calc(-100% - 72px);
  transition: all 0.75s ease-in-out;
  z-index: -1;
  background-color: $white-color;
  box-shadow: 0px 1px 4px 0px #00000033;
  &.show {
    top: 68px;
    z-index: 999;
  }
  .header-menu {
    padding: 16px;
    max-height: calc(100vh - 104px);
    overflow-y: auto;
    .menu-list {
      padding: 0;
      margin: 0;
      list-style: none;
      & > li {
        margin-bottom: 10px;
        & > button {
          width: 100%;
          display: flex;
          justify-content: space-between;
          background-color: transparent;
          border-width: 0 0 1px 0;
          padding: 10px;
          color: $primary-color;
          outline: none;
          border-color: $primary-color;
          font-size: 16px;
          transition: 0.35s;
          font-family: $metropolis-semiBold;
          &.active-menu {
            background-color: rgba($primary-color, 0.1);
          }
          svg {
            transition: 0.35s;
          }

          &.active {
            border-width: 0 0 2px 0;
            svg {
              transform: rotate(180deg);
            }
          }
        }
        .submenu {
          visibility: hidden;
          overflow: hidden;
          height: 0;
          padding: 0;
          transition: 0.35s;
          &.active {
            overflow: auto;
            height: auto;
            padding: 16px 0;
            visibility: visible;
          }
          .submenu-wrap {
            .submenu-details {
              display: none;
            }
            .submenu-list {
              .submenu-list-wrap {
                ul {
                  padding: 0;
                  margin: 0;
                  list-style: none;
                  li {
                    button {
                      margin-bottom: 6px;
                      padding: 4px 10px;
                      display: flex;
                      align-items: center;
                      color: $primary-color;
                      border-radius: 4px;
                      transition: 0.35s;
                      cursor: pointer;
                      border: 0;
                      background-color: transparent;
                      width: calc(100% - 20px);
                      font-size: 16px;
                      &:hover {
                        background-color: $primary-light-color;
                      }
                      svg {
                        color: $primary-color;
                        width: 20px;
                        height: 20px;
                        path {
                          fill: $primary-color;
                        }
                      }
                      span {
                        margin-left: 12px;
                        font-family: $metropolis-light;
                      }
                    }
                  }
                }
              }
              .view-all-btn {
                margin-top: 30px;
                @include lg {
                  margin-top: 6px;
                }
                button {
                  background-color: transparent;
                  border: 0;
                  padding: 0;
                  padding: 5px 10px;
                  font-size: 18px;
                  font-family: $metropolis-semiBold;
                  color: $primary-color;
                  cursor: pointer;
                  position: relative;
                  span {
                    transition: 0.35s;
                    margin-right: 4px;
                  }
                  img {
                    width: 30px;
                  }
                  &:hover {
                    span {
                      margin-right: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

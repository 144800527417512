@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.recruitment-process-section {
  padding-bottom: 140px;
  @include md {
    padding-bottom: 72px;
  }
  .recruitment-process-title {
    text-align: center;
    color: $primary-color;
    margin: 0 0 40px 0;
    @include sm {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 6px 0;
    }
  }
  .recruitment-process-slider {
    background: $primary-gradient;
    .d-block {
      display: block !important;
    }
    .recruitment-process-wrap {
      display: flex;
      align-items: center;
      padding: 1px;
      @include md {
        flex-flow: column;
      }
      .recruitment-process-img {
        position: relative;
        display: flex;
        align-items: center;
        width: 50%;
        @include md {
          width: 100%;
        }
        img {
          max-width: 100%;
          width: 100%;
        }
        .recruitment-process-count {
          position: absolute;
          right: -18%;
          font-size: 417px;
          font-family: $lato;
          font-weight: 900;
          color: transparent;
          -webkit-text-stroke: 0.58vw $white-color;
          @include lg {
            -webkit-text-stroke: 0.48vw $white-color;
            font-size: 300px;
          }
          @include md {
            right: 2%;
            bottom: 0%;
            -webkit-text-stroke: 0.38vw $white-color;
            font-size: 80px;
          }
          @include sm {
            font-size: 70px;
          }
        }
      }
      .recruitment-process-content {
        width: calc(50% - 240px);
        padding: 24px 120px;
        @include lg {
          width: calc(50% - 180px);
          padding: 12px 90px;
        }
        @include md {
          width: 100%;
          padding: 12px 0;
        }
        h4 {
          color: $primary-color;
          margin: 0 0 12px 0;
          @include lg {
            font-size: 20px;
            line-height: 24px;
          }
        }
        p {
          margin: 0;
          font-size: 20px;
          line-height: 28px;
          color: $primary-medium-color;
          font-family: $metropolis-light;
          font-weight: 400;
          @include lg {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .slick-dots {
      bottom: -40px;
      @include md {
        bottom: -25px;
      }
      li {
        @include md {
          width: 12px;
          height: 12px;
        }
        &.slick-active {
          width: 48px;
          @include md {
            width: 32px;
          }
          button {
            width: 100%;
            &::before {
              width: 100%;
              border-radius: 8px;
              background-color: $primary-color;
              transition: 0.4s;
            }
          }
        }
        button {
          @include md {
            width: 8px;
            height: 8px;
          }
          &::before {
            content: '';
            opacity: 1;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            border: 1px solid $primary-color;
            @include md {
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }
  }
}

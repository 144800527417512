@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.prominent-works-details {
  overflow: hidden;
  .prominent-works-details-wrap {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 60px;
    @include md {
      flex-flow: column;
      gap: 40px;
    }
    @include sm {
      gap: 18px;
    }
    .details-content {
      padding: 24px 0 24px 60px;
      width: 50%;
      color: $white-color;
      @include lg {
        padding-left: 24px;
      }
      @include md {
        width: calc(100% - 40px);
        padding: 16px 20px;
      }
      .brand-details {
        display: flex;
        gap: 10px;
        margin-bottom: 14px;
        align-items: center;
        .ring-icon {
          width: 68px;
          height: 68px;
          border-radius: 50%;
          border: 1px solid $white-color;
          padding: 5px;
          @include lg {
            width: 50px;
            height: 50px;
          }
          .img-icon {
            border-radius: 50%;
            background-color: $white-color;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            img {
              width: auto;
              max-height: 58px;
              max-width: 100%;
              @include lg {
                max-height: 42px;
              }
            }
          }
          .text-icon {
            width: 100%;
            height: 100%;
            background-color: $white-color;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              margin: 0;
              font-family: $lato;
              font-weight: 900;
              font-size: 20px;
              color: $primary-color;
            }
          }
        }
        .right-content {
          .brand-img {
            margin-bottom: 8px;
            img {
              max-width: 100%;
            }
          }
          .brand-title {
            margin: 0;
            font-size: 16px;
          }
        }
      }
      & > .project-sub-title {
        margin: 0 0 18px 0;
      }
      & > h2 {
        margin: 0 0 10px 0;
      }
      & > p {
        margin: 0 0 20px 0;
        font-size: 16px;
        line-height: 1.8;
        font-family: $metropolis-regular;
        @include lg {
          line-height: 1.6;
          margin: 0 0 36px 0;
        }
        @include sm {
          margin: 0 0 10px 0;
        }
      }
      & > .country-blog {
        display: flex;
        p {
          font-family: $metropolis-regular;
          font-size: 16px;
          font-weight: 300;
          line-height: 16px;
          text-align: left;
          margin-top: 0;
          margin-bottom: 6px;
          @include sm {
            font-size: 12px;
            line-height: 12px;
          }
        }
        span {
          font-family: $metropolis-regular;
          font-size: 20px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          @include sm {
            font-size: 16px;
            line-height: 16px;
          }
        }
        .country-container {
          width: 40%;
          @include sm {
            width: 50%;
          }
          .country {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            span {
              display: flex;
              align-items: center;
              gap: 6px;
            }
          }
        }
        .duration-container {
          width: 60%;
          @include sm {
            width: 50%;
          }
          .duration {
            margin-bottom: 26px;
          }
        }
      }
    }
    .content-padding {
      padding-bottom: 80px;
      @include md {
        padding-bottom: 0;
      }
    }
    .details-img {
      width: 50%;
      @include md {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
}

@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.project-list {
  .project-list-content {
    margin-bottom: 16px;
    padding: 24px 0;
  }
  .bg-blue {
    background-color: $primary-color;
  }
  .bg-midnight-slate {
    background-color: $midnight-slate-color;
  }
}

@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.contact-form {
  width: calc(50% - 41px);
  padding: 41px;
  background: $primary-color;
  @include lg {
    width: calc(50% - 20px);
    padding: 20px;
  }
  @include md {
    width: 70%;
    margin: 0 auto;
  }
  @include sm {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
  p {
    color: $white-color;
    font-family: $metropolis-regular;
    font-size: 20px;
    font-weight: 400;
    line-height: 30.4px;
    text-align: center;
    margin: 0 0 16px 0;
    @include sm {
      margin-top: 38px;
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 24.32px;
    }
  }
  .form {
    width: 100%;
    form {
      width: 100%;
      @include sm {
        display: flex;
        flex-direction: column;
      }
      .form-input {
        width: 100%;
        .input::placeholder{
          color: white;
        }
        .input {
          color: $white-color;
          height: 44px;
          width: calc(100% - 28px);
          border: 0.5px solid $border-color;
          background: transparent;
          margin-top: 16px;
          padding-left: 14px;
          padding-right: 14px;
          font-size: 16px;
          outline: none;
          @include sm {
            margin-top: 26px;
            height: 42px;
            font-size: 14px;
          }
        }
        .error-name {
          border: 0.5px solid $red-color;
        }
        .error-email {
          border: 0.5px solid $red-color;
        }
      }
      .error {
        color: $red-color;
        font-family: $metropolis-regular;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        margin-top: 3px;
      }
      .phone-container {
        background: $midnight-slate-light-color;
        border: 0.5px solid $border-color;
        width: 100%;
        height: 47px;
        margin-top: 16px;
        @include sm {
          height: 43px;
          width: 100%;
          font-size: 14px;
        }
        .phone {
          height: 47px;
          margin-bottom: 16px;
          padding-left: 11px;
          font-size: 16px;
          @include sm {
            height: 43px;
          }
          input {
            font-family: $metropolis-regular;
            background: $primary-color;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 1px;
            text-align: left;
            color: $white-color;
            height: 45px;
            width: 100%;
            border: 0.5px solid $border-color;
            border-right: none;
            border-top: none;
            border-bottom: none;
            margin: 16px 0px 16px -6px;
            padding-left: 14px;
            outline: none;
            @include sm {
              height: 41px;
              font-size: 14px;
            }
          }
          .PhoneInputCountrySelectArrow {
            margin-left: 10px;
            margin-right: 11px;
            transform: rotate(0deg);
            border-style: none;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $white-color;
            opacity: 1;
          }
        }
      }
      textarea {
        color: $white-color;
        background: transparent;
        font-family: $metropolis-regular;
        border: 0.5px solid $border-color;
        height: 128px;
        width: calc(100% - 28px);
        padding: 14px 14px 14px 14px;
        margin-bottom: 16px;
        margin-top: 16px;
        font-size: 16px;
        outline: none;
        resize: none;
        @include sm {
          margin-top: 26px;
          height: 130px;
          font-size: 14px;
        }
      }
      textarea::placeholder {
        color: white; /* Replace with your desired color */
        opacity: 1; /* This ensures the color is fully applied */
    }
      .primary-btn {
        width: 100%;
        height: 54px;
        margin-top: 53px;
        @include sm {
          width: 100%;
          height: 46px;
        }
        span {
          color: $white-color;
          font-size: 24px;
          line-height: 26.11px;
          @include sm {
            height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .loader {
          height: 54px;
          padding-left: 0px;
          margin-top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          @include sm {
            height: 0px;
          }
        }
      }
      .non-hover {
        pointer-events: none;
      }
      .btn-text {
        display: none !important;

        color: $white-color;
        font-family: $metropolis-regular;
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}

@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.how-it-works-wrapper {
  padding-bottom: 60px;
  @include sm {
    padding-bottom: 30px;
  }
  .how-it-works-content {
    text-align: center;
    h3 {
      color: $primary-color;
      margin: 0 0 10px 0;
      @include md {
        font-size: 26px;
        line-height: 34px;
      }
      @include sm {
        font-size: 24px;
        line-height: 32px;
      }
    }
    p {
      margin: 0 auto 20px auto;
      font-size: 18px;
      line-height: 28px;
      max-width: 90%;
      color: $primary-slate-color;
      @include md {
        font-size: 16px;
        line-height: 24px;
      }
      @include sm {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .center-content {
    display: flex;
    padding-bottom: 40px;
    gap: 20px;
    @include md {
      flex-wrap: wrap;
    }
    .how-it-works-title {
      width: 33.33%;
      padding-top: 72px;
      @include md {
        width: 100%;
        padding-top: 0px;
      }
      h4 {
        font-family: $metropolis-regular;
        line-height: 57px;
        font-weight: 300;
        margin: 0;
        color: $primary-color;
        @include rwd(1400) {
          font-size: 28px;
          line-height: 48px;
        }
        @include xl {
          font-size: 24px;
        }
        @include lg {
          font-size: 20px;
          line-height: 36px;
        }
        @include md {
          font-size: 16px;
          line-height: 24px;
        }
        @include sm {
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          max-width: 400px;
          margin: auto;
        }
      }
    }
    .how-it-works-step {
      width: 33.33%;
      padding-top: 72px;
      @include md {
        width: calc(60% - 10px);
        padding-top: 38px;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          margin-bottom: 42px;
          display: flex;
          align-items: center;
          gap: 51px;
          @include rwd(1400) {
            gap: 40px;
          }
          @include xl {
            gap: 30px;
          }
          @include lg {
            gap: 12px;
            margin-bottom: 36px;
          }
          @include md {
            margin-bottom: 24px;
          }
          @include sm {
            margin-bottom: 18px;
          }
          &:last-child() {
            margin-bottom: 0px;
          }
          .step-icon {
            @include xl {
              width: 40px;
            }
            @include lg {
              width: 36px;
            }
            @include md {
              width: 34px;
            }
            @include sm {
              width: 28px;
            }
            img {
              max-width: 100%;
            }
          }
          span {
            font-size: 24px;
            color: $primary-color;
            @include rwd(1400) {
              font-size: 22px;
            }
            @include xl {
              font-size: 20px;
            }
            @include lg {
              font-size: 18px;
            }
            @include md {
              font-size: 16px;
            }
            @include sm {
              font-size: 14px;
            }
          }
        }
      }
    }
    .how-it-works-img {
      width: 33.33%;
      padding-left: 36px;
      @include md {
        width: calc(40% - 46px);
      }
      @include sm {
        padding-left: 19px;
        width: calc(40% - 31px);
      }
      .img-wrapper {
        position: relative;
        padding-bottom: 72px;
        width: fit-content;
        margin-left: auto;
        @include sm {
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 38px;
        }
        &::after {
          content: '';
          position: absolute;
          left: -36px;
          top: 72px;
          width: 72px;
          height: calc(100% - 72px);
          background-color: rgba($primary-color, 0.8);
          @include sm {
            left: -19px;
            top: 38px;
            width: 38px;
            height: calc(100% - 38px);
          }
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
  .service-content-text {
    text-align: center;
    p {
      margin: 0 auto 20px auto;
      font-size: 20px;
      line-height: 28px;
      max-width: 90%;
      color: $primary-slate-color;
      @include lg {
        font-size: 18px;
        line-height: 24px;
      }
      @include md {
        font-size: 16px;
        line-height: 24px;
      }
      @include sm {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.hero-section {
  margin-top: -81px;
  min-height: 707px;
  background: linear-gradient(77.16deg, #cfdcfa 0%, #d5d7f2 51%, #b9e0f7 100%);
  position: relative;
  z-index: -1;
  @include md {
    margin-top: -68px;
  }
  #tsparticles {
    height: 100vh !important;
    max-height: 707px;
  }
}
.hero-section-content {
  height: calc(707px - 80px);
  width: 100%;
  position: absolute;
  left: 0;
  top: 80px;
  @include md {
    height: calc(707px - 68px);
    top: 68px;
  }
  .container {
    height: 100%;
    @include md {
      padding: 0;
    }
  }
  .hero-section-wrapper {
    display: flex;
    position: relative;
    height: 100%;
    @include md {
      overflow: hidden;
    }
    .hero-section-text {
      display: flex;
      flex-flow: column;
      justify-content: center;
      max-width: 60%;
      @include md {
        position: relative;
        z-index: 1;
        max-width: 100%;
        width: 100%;
        padding: 0 16px;
      }
      h1 {
        color: $primary-color;
        margin: 0 0 16px 0;
        max-width: 700px;
        text-align: left;
        @include lg {
          font-size: 32px;
          max-width: 500px;
          line-height: 40px;
        }
        @include md {
          text-align: center;
          max-width: 500px;
          margin: 0 auto 40px;
        }
        @include sm {
          max-width: 350px;
        }
        @include rwd(425) {
          max-width: 340px;
        }
        @include rwd(375) {
          max-width: 340px;
        }
        & > span {
          // color: $minimal-color;
          color: transparent;
          -webkit-text-stroke: 0.08vw $primary-color;
          @include md {
            -webkit-text-stroke: 0.15vw $primary-color;
          }
          @include rwd(375) {
            display: block;
            -webkit-text-stroke: 0.2vw $primary-color;
          }
        }
      }
      p {
        letter-spacing: 2px;
        font-family: $metropolis-regular;
        color: $primary-medium-color;
        line-height: 28px;
        font-size: 16px;
        max-width: 714px;
        margin: 0 0 60px 0;
        @include lg {
          letter-spacing: 1px;
          margin: 0 0 40px 0;
        }
        @include md {
          text-align: center;
          max-width: 500px;
          margin: 0 auto 16px;
        }
        @include sm {
          max-width: 380px;
        }
      }
      .get-in-touch {
        width: 255px;
        @include md {
          margin: 0 auto;
        }
        .primary-btn {
          width: 100%;
          &:hover {
            span {
              color: $primary-color;
              border: 1px solid $primary-color;
            }
          }
        }
      }
    }
    .hero-section-img {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 40%;
      @include md {
        width: 100%;
        right: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 650px;
      }
      img {
        width: 100%;
      }
      .desktop-hero-section-img {
        @include md {
          display: none;
        }
      }
      .mobile-hero-section-img {
        display: none;
        @include md {
          display: block;
        }
      }
    }
  }
}

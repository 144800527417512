@import "../../Assets//Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.blog-slick-slider {
  margin: 12px auto 80px auto;
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    bottom: -50px !important;
    li {
      &.slick-active {
        width: 48px;
        button {
          width: 100%;
          &::before {
            width: 100%;
            border-radius: 8px;
            background-color: $primary-color;
            transition: 0.4s;
          }
        }
      }
      button {
        &::before {
          content: "";
          opacity: 1;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          border: 1px solid $primary-color;
        }
      }
    }
  }
  .blog-slider-card {
    width: calc(50% - 18px);
    display: flex !important;
    cursor: pointer;
    .card-img-div {
      width: 40%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        min-height: 252px;
      }
    }
    .card-content-div {
      width: 60%;
      margin: 4px 16px;
      color: $primary-color;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      h4 {
        font-family: Metropolis;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
      }
      .card-description {
        font-family: Metropolis;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 12px 0 16px 0;
        text-align: left;
      }
      .card-date {
        font-family: Metropolis;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
      }
    }
  }
}

@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.agencies {
  margin-bottom: 116px;
  margin-top: 140px;
  width: 100%;
  @include md {
    margin-bottom: 72px;
    margin-top: 72px;
  }
  .agencies-header {
    h4 {
      color: $primary-color;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 10px;
    }
    p {
      font-family: $metropolis-regular;
      color: $primary-medium-color;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 6px;
      @include md {
        font-size: 14px;
      }
    }
  }
}

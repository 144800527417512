@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.blog-section {
  padding: 52px 0 140px 0;
  @include md {
    padding: 20px 0 72px 0;
  }
  .blog-tab {
    margin-bottom: 40px;
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      @include md {
        display: none;
      }
      li {
        list-style: none;
        padding: 0px 16px;
        @include lg {
          padding: 0px 8px;
        }
        &.active {
          button {
            color: $primary-color;
            font-family: $metropolis-bold;
          }
        }
        button {
          transition: 0.35s;
          font-size: 18px;
          cursor: pointer;
          padding: 8px 10px;
          background-color: transparent;
          border: 0;
          font-family: $metropolis-light;
          color: $midnight-slate-dark-color;
        }
      }
    }
    .category-select {
      display: none;
      max-width: 260px;
      margin: auto;
      position: relative;
      @include md {
        display: block;
      }
      &::after {
        content: "";
        position: absolute;
        border: solid $primary-color;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        vertical-align: middle;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 16px;
        right: 14px;
      }
      select {
        width: 100%;
        padding: 16px 8px;
        outline: none;
        border: 1px solid $primary-color;
        color: $primary-color;
        background-color: transparent;
        appearance: none;
        font-family: $metropolis-bold;
        option {
          font-family: $metropolis-medium;
        }
      }
    }
  }
  .blog-wrap {
    display: flex;
    flex-flow: wrap;
    gap: 72px;
    row-gap: 100px;
    @include lg {
      gap: 48px;
      row-gap: 56px;
    }
    @include md {
      row-gap: 48px;
    }
    .blog-card {
      opacity: 0;
      transition: opacity 500ms linear;
      width: calc(50% - 36px);
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      @include lg {
        width: calc(50% - 24px);
      }
      @include md {
        width: 100%;
      }
      .blog-details {
        .blog-img {
          img {
            max-width: 100%;
            max-width: 100%;
            // max-height: 280px;
            object-fit: contain;
          }
        }
        .blog-content {
          h2 {
            margin: 8px 0 12px 0;
            font-size: 26px;
            line-height: 28px;
            font-family: $lato;
            font-weight: 900;
            @include md {
              font-size: 18px;
              line-height: 20px;
            }
            a {
              color: $primary-color;
              text-decoration: none;
              transition: 0.5s;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          p.sub-title {
            color: $primary-medium-color;
            margin: 0;
            font-family: $metropolis-light;
            font-size: 16px;
            line-height: 28px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            @include md {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
      .blogger-details {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 20px;
        @include md {
          margin-top: 22px;
        }
        .blogger-img {
          width: 52px;
          height: 52px;
          min-width: 52px;
          @include md {
            width: 32px;
            height: 32px;
            min-width: 32px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .blogger-content {
          p {
            color: $primary-medium-color;
            margin: 0 0 6px 0;
            font-family: $metropolis-semiBold;
            font-size: 20px;
            line-height: 20px;
            @include md {
              margin: 0 0 3px 0;
              font-size: 12px;
              line-height: 12px;
            }
          }
          span {
            color: $primary-medium-color;
            margin: 0;
            font-family: $metropolis-light;
            font-size: 16px;
            line-height: 16px;
            @include md {
              font-size: 10px;
              line-height: 10px;
            }
          }
        }
      }
    }
    .no-blog-found {
      opacity: 0;
      transition: opacity 500ms linear;
      text-align: center;
      width: 100%;
      color: $primary-medium-color;
    }
  }
}

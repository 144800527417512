@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.Faq {
  margin-bottom: 140px;
  @include md {
    margin-bottom: 72px;
  }
  .container {
    h4 {
      color: $primary-color;
      text-align: center;
      margin-top: 0;
      margin-bottom: 40px;
    }

    .accordion {
      width: 100%;
      .accordion-item {
        margin-bottom: 24px;
        @include md {
          margin-bottom: 14px;
        }
        .accordion-title {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          cursor: pointer;
          background: transparent;
          border: none;
          padding-bottom: 2px;
          padding-left: 0;
          padding-right: 0;
          span {
            color: $primary-color;
            font-family: $metropolis-regular;
            font-size: 30px;
            font-weight: 400;
            text-align: left;
            padding-right: 34px;

            @include md {
              padding-right: 10px;
              font-size: 20px;
              line-height: 25.4px;
            }
          }
          .icon {
            min-width: 34px;
            height: 34px;
            border-radius: 50%;
            background: $primary-light-color;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease-in-out;
            @include sm {
              min-width: 24px;
              height: 24px;
            }
            p {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $primary-color;
              font-size: 26px;
              margin: 0;
              transition: transform 0.3s ease-in;
              @include md {
                font-size: 18px;
              }
            }
          }
        }

        .accordion-content {
          width: calc(100% - 34px);
          color: $primary-medium-color;
          font-family: $metropolis-regular;
          font-size: 20px;
          font-weight: 400;
          line-height: 30.4px;
          text-align: left;
          padding-right: 34px;
          @include md {
            font-size: 14px;
            line-height: 18.24px;
          }
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.3s ease-in-out;

          &.open {
            max-height: 500px;
          }
        }
      }
    }
  }
}

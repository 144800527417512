@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.contactDetails {
  width: 50%;
  margin-left: 106px;
  margin-top: 40px;
  @include lg {
    margin-left: 50px;
  }
  @include md {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  h4 {
    color: $primary-color;
    margin-bottom: 36px;
    margin-top: 0px;
    @include md {
      display: none;
    }
  }
  h2 {
    color: $primary-color;
    margin-bottom: 14px;
    @include md {
      margin-top: 0;
    }
    @include sm {
      font-size: 30px;
      letter-spacing: 0px;
      margin-bottom: 11px;
    }
  }
  p {
    color: $primary-color;
    font-family: $metropolis-regular;
    font-size: 32px;
    font-weight: 300;
    text-align: left;
    line-height: 48.64px;
    margin-top: 0px;
    margin-bottom: 44px;
    @include sm {
      margin-bottom: 29px;
      font-size: 18px;
      line-height: 27.36px;
    }
  }
  .points {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    @include md {
      margin-bottom: 16px;
    }
    .checkIcon {
      color: $minimal-color;
      width: 34px;
      height: 34px;
      top: 6.79px;
      left: 3.38px;
      gap: 0px;
      margin-right: 8px;
      @include sm {
        width: 20px;
        height: 20px;
      }
    }
    span {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: $metropolis-regular;
      color: $primary-color;
      font-size: 18px;
      font-weight: 300;
      line-height: 18px;
      text-align: left;
      @include sm {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

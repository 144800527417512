@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.fullService {
  position: relative;
  height: 730px;
  display: flex;
  margin-bottom: 141px;
  @include lg {
    height: 730px;
  }
  @include md {
    width: 100%;
    flex-flow: column;
    margin-bottom: 72px;
    height: auto;
  }
  .background-blue {
    width: 50%;
    height: 100%;
    background: $primary-color;
    @include md {
      width: 100%;
      display: none;
    }
  }
  .background-gary {
    width: 50%;
    height: 100%;
    background: $gray-color;
    @include md {
      width: 100%;
      display: none;
    }
  }
  .services {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    @include md {
      position: initial;
      flex-direction: column;
    }
    .container {
      display: flex;
      height: 100%;

      @include md {
        width: 100%;
        flex-flow: column;
        padding: 0;
      }
    }
  }
}

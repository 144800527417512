@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.prominent-works {
  .prominent-works-main {
    & > h2 {
      text-align: center;
      color: $primary-color;
      margin: 0 0 36px 0;
      @include md {
        margin: 0 0 20px 0;
      }
    }
    .prominent-works-wrap {
      display: flex;
      align-items: center;
      @include md {
        flex-flow: column;
      }
      &.bg-blue {
        background-color: $primary-color;
      }
      &.bg-midnight-slate {
        background-color: $midnight-slate-color;
      }
      .prominent-works-list-icon {
        min-width: 142px;
        @include lg {
          min-width: 100px;
        }
        @include md {
          min-width: 100%;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          @include md {
            display: flex;
            width: 100%;
          }
          li {
            height: 142px;
            border: 1px solid $white-color;
            border-width: 0 1px 1px 0;
            @include lg {
              height: 100px;
            }
            @include md {
              width: 25%;
            }
            &.active {
              border-width: 0 0 1px 0;
              @include md {
                border-width: 0 1px 0px 0;
              }
            }
            button {
              background-color: transparent;
              border: 0;
              padding: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
            .ring-icon {
              width: 78px;
              height: 78px;
              border-radius: 50%;
              border: 1px solid $white-color;
              padding: 5px;
              @include lg {
                width: 50px;
                height: 50px;
              }
              .img-icon {
                border-radius: 50%;
                background-color: $white-color;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                img {
                  width: auto;
                  max-height: 61px;
                  max-width: 100%;
                  @include lg {
                    max-height: 42px;
                  }
                }
              }
              .text-icon {
                width: 100%;
                height: 100%;
                background-color: $white-color;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                  margin: 0;
                  font-family: $lato;
                  font-weight: 900;
                  font-size: 20px;
                  color: $primary-color;
                  @include lg {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .prominent-works-section {
      width: 100%;
    }
  }
}

@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.testimonial {
  margin-bottom: 140px;
  @include md {
    margin-bottom: 72px;
  }
  .container {
    .testimonial-common-div {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .testimonial-container {
        margin-bottom: 26px;
        padding-bottom: 50px;
        width: 47%;
        margin: 30px auto;
        border-bottom: 2px solid $minimal-color;
        @include md {
          width: 95%;
          padding-bottom: 8px;
          margin-bottom: 12px;
        }

        .testimonial-content {
          padding-left: 20px;
          align-items: center;
          @include md {
            padding-left: 0;
          }
          .review-text {
            display: flex;
            flex-direction: column;
            img {
              width: 20px;
              height: 16px;
              margin-bottom: 0;
            }
            p {
              color: $primary-color;
              font-family: $metropolis-regular;
              font-size: 18px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              padding-left: 30px;
              margin-bottom: 0px;
              margin-top: 0;
            }
          }
          .testimonial-detail {
            width: 80%;
            margin: 0 26px;

            .client-name {
              color: $primary-color;
              text-align: left;
              font-size: 32px;
              font-weight: 600;
              margin: 16px 0 0px 0;
            }
            .client-position {
              color: $primary-color;
              margin: 8px 0px 12px 0;
            }
          }
        }
        &:nth-child(even) {
          flex-direction: row-reverse;
          @include md {
            flex-direction: column;
          }
          .testimonial-content {
            padding-left: 0;
            .review-text {
              p {
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

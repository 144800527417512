@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.blog-details-wrap {
  display: flex;
  gap: 36px;
  padding: 72px 0;

  @media (max-width: 572px) {
    display: block;
    padding: 12px 0;
  }
  .blog-description-details {
    width: 66.66%;
    @media (max-width: 572px) {
      width: 100%;
    }
    & > * {
      margin: 0 0 12px 0;
      color: $primary-medium-color;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $primary-color;
      margin: 16px 0 12px 0;
    }
    li {
      margin: 12px 0px;
      font-size: 18px;
      line-height: 22px;
    }
    img {
      max-width: 100%;
    }
    p {
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 26px;
    }
  }
  .other-blog-links {
    width: 33.33%;
    @media (max-width: 572px) {
      width: 100%;
      padding: 12px 0;
    }
    h5 {
      margin: 0 0 24px 0;
      color: $primary-color;
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        margin: 0 0 20px 0;
        a {
          text-decoration: none;
          color: $primary-medium-color;
          &:hover {
            svg {
              transition: 0.3s;
              margin-left: 8px;
              margin-right: 8px;
            }
          }
          svg {
            margin-right: 16px;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

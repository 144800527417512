@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.we-serve-section {
  padding: 140px 0;
  @include xl {
    padding: 80px 0;
  }
  @include md {
    padding: 72px 0;
  }
  .we-serve-section-title {
    text-align: center;
    font-family: $lato;
    font-weight: 900;
    font-size: 32px;
    color: $primary-color;
    margin: 0 0 36px 0;
    @include md {
      margin: 0 0 20px 0;
      font-size: 24px;
    }
  }
  .first-slider {
    border: 1px solid $primary-color;
    border-width: 1px 0 1px 0;
    margin-bottom: 56px;
    position: relative;
    @include lg {
      margin-bottom: 36px;
    }
    @include md {
      margin-bottom: 20px;
    }
    &::after {
      content: '';
      position: absolute;
      width: 150px;
      height: calc(100% + 2px);
      background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 130%);
      left: 0;
      top: -1px;
      @include lg {
        width: 80px;
      }
    }
    &::before {
      content: '';
      position: absolute;
      width: 150px;
      height: calc(100% + 2px);
      background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 130%);
      right: 0;
      top: -1px;
      z-index: 1;
      @include lg {
        width: 80px;
      }
    }
    p {
      color: $primary-medium-color;
      white-space: nowrap;
      padding: 0 48px;
      font-size: 18px;
      font-family: $metropolis-semiBold;
      cursor: pointer;
      @include xl {
        padding: 0 36px;
      }
      @include sm {
        padding: 0 20px;
      }
    }
    .slick-active {
      p {
        color: $minimal-color;
      }
    }
  }
  .second-slider {
    padding: 0 80px;
    @include md {
      padding: 0 40px;
    }
    .slick-prev {
      left: 0;
      width: 52px;
      height: auto;
      @include md {
        width: 24px;
      }
    }
    .slick-next {
      right: 0;
      width: 52px;
      height: auto;
      @include md {
        width: 24px;
      }
    }
    .slick-slide {
      &.slick-active {
        .carousel-content-wrap {
          .carousel-content-img {
            transform: translateY(0);
          }
        }
        .carousel-content-text {
          h4 {
            transform: translateY(0);
          }
          p {
            transform: translateY(0);
          }
        }
      }
    }
    .carousel-content-wrap {
      display: flex;
      align-items: center;
      max-width: 918px;
      margin: auto;
      gap: 48px;
      @include md {
        flex-flow: column;
        gap: 10px;
      }
      .carousel-content-img {
        width: 50%;
        transition: 1s;
        transition-delay: 0.5s;
        transform: translateY(20px);
        @include md {
          width: 100%;
          text-align: center;
          max-width: 226px;
        }
        img {
          width: 100%;
        }
      }
      .carousel-content-text {
        width: 50%;
        max-width: 318px;
        @include md {
          width: 100%;
          text-align: center;
        }
        h4 {
          margin: 0 0 16px 0;
          color: $primary-color;
          transition: 1s;
          transition-delay: 0.5s;
          transform: translateY(20px);

          @include lg {
            font-size: 28px;
          }
          @include md {
            text-align: center;
            font-size: 24px;
            margin: 0 0 4px 0;
          }
        }
        p {
          font-family: $metropolis-regular;
          color: $primary-medium-color;
          line-height: 28px;
          font-size: 16px;
          margin: 0;
          transition: 1.2s;
          transition-delay: 0.5s;
          transform: translateY(20px);
          @include lg {
            line-height: 24px;
          }
          @include md {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.hero-text {
  padding: 120px 0 44px 0;
  text-align: center;
  @include md {
    padding: 60px 0 22px 0;
  }
  h1 {
    color: $primary-color;
    margin: 0 auto 20px auto;
    max-width: 95%;
    @include md {
      max-width: 100%;
      margin: 0 auto 10px auto;
    }
  }
  h6 {
    color: $primary-medium-color;
    max-width: 1035px;
    margin: 0 auto;
    font-weight: 500;
    @include md {
      font-size: 14px;
    }
  }
  img {
    margin: 26px 0px 0px 0px;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.services-details {
  width: calc(50% - 72px);
  padding: 94px 36px 94px 36px;
  @include lg {
    width: calc(100% - 16px);
    padding: 32px 0px 32px 16px;
  }
  @include md {
    width: calc(100% - 50px);
    padding: 32px 34px 32px 16px;
    background: $primary-color;
  }
  .details {
    display: flex;
    margin-bottom: 30px;
    .icon {
      width: 42px;
      height: 42px;
      margin: 0px;
      svg {
        width: 100%;
        height: 100%;
        color: $white-color;
        path {
          fill: $white-color;
        }
      }
    }
    .content {
      width: calc(100% - 26px);
      color: $white-color;
      margin-left: 26px;
      h6 {
        margin-top: 0px;
        margin-bottom: 8px;
        @include sm {
          margin-bottom: 6px;
        }
      }
      p {
        margin: 0px;
        font-family: $metropolis-light;
        font-size: 16px;
        font-weight: 400;
        line-height: 24.32px;
        text-align: left;
        @include sm {
          font-size: 12px;
          line-height: 18.24px;
        }
      }
    }
  }
}

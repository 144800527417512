@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';
.service-details-info {
  padding-bottom: 140px;
  @include xl {
    padding-bottom: 120px;
  }
  @include md {
    padding-bottom: 72px;
  }
  .service-details-wrap {
    display: flex;
    .service-details-content {
      width: 66.66%;
      background-color: $gray-color;
      position: relative;
      min-height: 644px;
      @include md {
        width: 50%;
        min-height: 500px;
      }
      @include sm {
        min-height: 400px;
      }
      @include rwd(425) {
        min-height: 300px;
      }
      @include rwd(375) {
        min-height: 275px;
      }
      .mobile-service-details-title {
        display: none;
        @include md {
          display: block;
        }
        h3 {
          font-size: 28px;
          line-height: 36px;
          color: $primary-color;
          max-width: 280px;
          margin: 10px auto;
          padding: 0 16px;
          @include sm {
            font-size: 20px;
            line-height: 28px;
            max-width: 180px;
          }
          @include rwd(425) {
            padding: 0 12px;
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
      .content-text {
        padding: 32px 82px;
        @include xl {
          padding: 32px 52px;
        }
        @include lg {
          padding: 20px 28px;
        }
        @include md {
          display: none;
        }
        h4 {
          margin: 0 0 32px 0;
          max-width: 500px;

          color: $primary-color;
          @include lg {
            margin: 0 0 24px 0;
          }
        }
        p {
          max-width: 72%;
          margin: 0 0 60px 0;
          font-size: 20px;
          line-height: 36px;
          color: $primary-medium-color;
          font-family: $metropolis-light;
          @include xl {
            margin: 0 0 40px 0;
            font-size: 18px;
            line-height: 32px;
          }
          @include lg {
            font-size: 16px;
            line-height: 28px;
            max-width: 70%;
          }
        }
        ul {
          padding-left: 72px;
          max-width: 68%;
          li {
            font-size: 20px;
            line-height: 36px;
            color: $primary-medium-color;
            font-family: $metropolis-light;
            @include xl {
              font-size: 18px;
              line-height: 32px;
            }
          }
        }
      }
      .vector-shape-ui {
        position: absolute;
        left: 0;
        bottom: -62px;
        @include xl {
          max-width: 130px;
          bottom: -45px;
        }
        @include lg {
          max-width: 115px;
          bottom: -40px;
        }
        @include rwd(425) {
          max-width: 60px;
          bottom: -20px;
        }
        img {
          max-width: 100%;
        }
      }
    }
    .service-details-right {
      min-width: 33.33%;
      background-color: $primary-color;
      position: relative;
      @include md {
        width: 50%;
      }
      .service-details-title {
        @include md {
          display: none;
        }
        h3 {
          color: $white-color;
          max-width: 280px;
          margin: 10px auto;
          padding: 0 16px;
          @include lg {
            font-size: 32px;
          }
        }
      }
      .round-shape-ui {
        position: absolute;
        left: -50%;
        width: 100%;
        @include md {
          top: 50%;
          transform: translateY(-50%);
        }
        img {
          max-width: 100%;
          transform: rotate(180deg);
        }
        &.active {
          img {
            transition: all 1s ease-in-out;
            transform: rotate(0deg);
          }
        }
      }
      .service-mockup {
        position: absolute;
        left: -50%;
        width: 100%;
        @include md {
          top: 50%;
          transform: translateY(-50%);
        }
        img {
          opacity: 0;
          transform: scale(0.2);
          max-width: 100%;
        }
        &.active {
          img {
            animation-name: bounceImg;
            animation-duration: 2s;
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }
  .mobile-view {
    display: none;
    @include md {
      display: block;
    }
    .content-text {
      h4 {
        max-width: 500px;
        text-transform: capitalize;
        color: $primary-color;
        margin: 60px 0 12px 0;
      }
      p {
        max-width: 90%;
        text-align: center;
        margin: 0 auto 32px auto;
        font-size: 12px;
        line-height: 18px;
        color: $primary-medium-color;
        font-family: $metropolis-light;
      }
      ul {
        padding-left: 24px;
        li {
          color: $primary-medium-color;
          font-family: $metropolis-light;
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }
}

@keyframes bounceImg {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  50% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@import '../../Assets/Scss/Variable';
@import '../../Assets/Scss/Mixins.scss';

.footer-section {
  background-color: $primary-color;
  color: $white-color;
  .footer-wrapper {
    display: flex;
    padding: 42px 0;
    gap: 36px;
    @include lg {
      gap: 24px;
    }
    @include md {
      flex-flow: column;
      gap: 0px;
      padding: 20px 0;
    }
    h6 {
      font-size: 18px;
      margin: 0 0 8px;
      @include sm {
        font-size: 14px;
      }
    }
    .brand-content {
      width: 25%;
      @include md {
        width: 100%;
      }
      .footer-brand {
        .footer-logo {
          @include md {
            max-width: 200px;
          }
          @include sm {
            max-width: 140px;
          }
          img {
            max-width: 100%;
          }
        }
        p {
          font-family: $metropolis-light;
          line-height: 24px;
          margin: 20px 0 40px;
          @include xl {
            font-size: 14px;
          }
          @include sm {
            font-size: 12px;
            margin: 8px 0 30px;
          }
        }
      }
      .address {
        .location-title {
          // margin: 0 0 16px;
          img {
            max-width: 100%;
          }
        }
        ul {
          margin: 0;
          padding: 0;
          @include md {
            display: flex;
            gap: 16px;
          }
          li {
            list-style: none;
            margin-bottom: 20px;
            @include md {
              width: 50%;
              margin-bottom: 34px;
            }
            h6 {
              display: flex;
              justify-content: left;
              align-items: center;
              gap: 10px;
              span {
                display: flex;
                svg {
                  height: 26px;
                  width: 26px;
                }
              }
            }
            p {
              font-family: $metropolis-light;
              line-height: 24px;
              margin: 0;
              @include xl {
                font-size: 14px;
              }
              @include sm {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .footer-link-details {
      width: 75%;
      @include md {
        width: 100%;
      }
      .footer-contact-details-wrap {
        display: flex;
        @include md {
          gap: 16px;
        }
        .contact-list {
          width: 33.33%;
          padding: 0 16px;
          border-right: 1px solid $primary-semi-color;
          @include md {
            width: 100%;
            padding: 0;
            border-right: 0px solid;
          }
          &:first-child {
            max-width: 240px;
            .content-detail {
              margin: 0;
            }
          }
          &:last-child {
            border-right: 0px solid;
            padding: 0 0 0 16px;
            @include md {
              padding: 0;
            }
            @include sm {
              display: none;
            }
            .content-detail {
              max-width: 250px;
              margin: 0 0 0 auto;
              @include md {
                margin: 0;
              }
            }
          }
          .content-detail {
            max-width: 200px;
            margin: auto;
            @include md {
              margin: 0;
            }
          }
          .contact-list-wrap {
            display: flex;
            align-items: center;
            gap: 12px;
            @include lg {
              gap: 8px;
            }
            span {
              color: $minimal-color;
              svg {
                width: 20px;
                height: 20px;
                @include md {
                  width: 16px;
                  height: 16px;
                }
              }
            }
            a {
              color: $white-color;
              text-decoration: none;
              font-family: $metropolis-light;
              @include xl {
                font-size: 14px;
              }
              @include sm {
                font-size: 12px;
              }
            }
            p {
              margin: 0;
              font-family: $metropolis-light;
              line-height: 22px;
              @include xl {
                font-size: 14px;
              }
              @include sm {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .newsletter-logo {
    display: flex;
    justify-content: space-between;
    .satyameva-jayate {
      .satya {
        display: flex;
        align-items: center;
        gap: 15px;
        @include md {
          margin-top: 23px;
          gap: 10px;
          img {
            height: 60px;
          }
        }
        @include sm {
          img {
            height: 53px;
          }
        }
        p {
          color: $white-color;
          font-family: $metropolis-light;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          margin: 0;
          @include xl {
            font-size: 14px;
          }
          @include md {
            line-height: 18px;
          }
          @include sm {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
    .verified-logo {
      display: flex;
      gap: 12px;
    }
  }
  .our-news-letter {
    padding-left: 16px;
    width: 50%;
    @include md {
      padding: 0 0 30px 0;
      display: none;
    }
    &.mobile-view {
      display: none;
      @include md {
        display: block;
      }
    }
    .email-input {
      position: relative;
      width: 100%;
      max-width: 392px;
      @include lg {
        max-width: 300px;
      }
      @include md {
        max-width: 570px;
      }
      input {
        width: calc(100% - 44px);
        background-color: transparent;
        border: 0;
        outline: none;
        border-bottom: 1px solid $minimal-color;
        color: $white-color;
        padding: 13px 25px 13px 6px;
        font-size: 16px;
        transition: 0.3s;
        font-family: $metropolis-light;
        @include xl {
          font-size: 14px;
        }
        @include sm {
          font-size: 12px;
        }
        &::placeholder {
          color: $white-color;
        }
      }
      button {
        background-color: transparent;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        cursor: pointer;
      }
    }
  }
  .footer-link-details-wrap {
    display: flex;
    margin: 58px 0;
    @include md {
      display: none;
    }
    .footer-link-wrap {
      width: 33.33%;
      padding: 0 16px;
      &.blog-list {
        @include rwd(425) {
          display: none;
        }
        ul {
          li {
            margin-bottom: 20px;
            position: relative;
            &::after {
              content: '';
              width: 80px;
              height: 1px;
              background-color: $primary-semi-color;
              position: absolute;
              top: -10px;
              left: 0;
            }
            &.view-all-blogs-link,
            &:first-child {
              &::after {
                content: initial;
              }
            }
          }
        }
      }
      &:first-child {
        max-width: 240px;
        .content-detail {
          margin: 0;
        }
      }
      &:last-child {
        padding: 0 0 0 16px;
        .content-detail {
          max-width: 250px;
          margin: 0 0 0 auto;
        }
      }
      .content-detail {
        max-width: 200px;
        margin: auto;
      }
      h6 {
        margin-bottom: 20px;
        @include sm {
          margin-bottom: 10px;
        }
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          margin-bottom: 16px;
          @include xl {
            margin-bottom: 12px;
          }
          &.view-all-blogs-link {
            a {
              display: flex;
              align-items: center;
              gap: 8px;
              transition: 0.3s;
              &:hover {
                gap: 16px;
              }
              img {
                filter: brightness(0) invert(1);
                width: 24px;
              }
            }
          }
          a {
            color: $white-color;
            text-decoration: none;
            font-family: $metropolis-light;
            line-height: 26px;
            @include xl {
              font-size: 14px;
            }
            @include sm {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .footer-link-details-wrap {
    &.mobile-view {
      display: none;
      @include md {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin: 0 0 16px 0;
        justify-content: space-between;
      }
      .footer-link-wrap {
        @include md {
          width: auto;
          padding: 0;
          &.blog-list {
            // width: 100%;
            padding: 0;
            max-width: 165px;
            .content-detail {
              margin: 0;
            }
          }
          @include rwd(425) {
            width: calc(50% - 12px);
          }
        }
      }
    }
  }
  .pixerfect-rights {
    border-top: 1px solid $primary-semi-color;
    padding: 22px 0;
    @include md {
      padding: 20px 0;
    }
    .pixerfect-rights-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include md {
        // flex-flow: column;
        align-items: flex-start;
        gap: 24px;
      }
      .pixerfect-rights-text {
        margin: 0;
        @include xl {
          font-size: 14px;
        }
        // @include md {
        //   order: 1;
        // }
        @include sm {
          font-size: 12px;
        }
      }
      .social-media {
        display: flex;
        padding: 0;
        margin: 0;
        gap: 28px;
        @include md {
          gap: 16px;
        }
        li {
          list-style: none;
          a {
            color: $white-color;
            transition: 0.3s;
            &:hover {
              color: $minimal-color;
            }
            svg {
              width: 24px;
              height: 24px;
              @include md {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
    .scroll-to-top {
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 1000;
    }

    .scroll-to-top button {
      background-color: $minimal-color;
      color: #fff;
      border: none;
      width: 50px;
      height: 50px;
      font-size: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
    }
  }
}
.newsletter-container p {
  color: $red-color;
  font-family: $metropolis-regular;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 0;
}
.clutch-logo {
  height: 76px;
  width: 76px;
}

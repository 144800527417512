@import '../../Assets/Scss/Variable.scss';
@import '../../Assets/Scss/Mixins.scss';

.not-found-wrap {
  text-align: center;
  padding: 140px 0;
  .not-found-img {
    img {
      max-width: 100%;
    }
  }
  .not-found-content {
    h2 {
      color: $primary-color;
    }
    p {
      max-width: 770px;
      margin: 0 auto 20px;
      font-weight: 400;
      font-family: $metropolis-light;
      font-size: 18px;
      line-height: 24px;
      color: $primary-medium-color;
    }
    .go-back {
      button {
        width: 100%;
        max-width: 200px;
      }
    }
  }
}

@import "./Assets/Scss/Variable.scss";
@import "./Assets/Scss/Mixins.scss";
@import "aos/dist/aos.css";
@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap";

@font-face {
  font-family: $metropolis-light;
  src: url("./Assets/Fonts/Metropolis/Metropolis-Light.otf");
}
@font-face {
  font-family: $metropolis-regular;
  src: url("./Assets/Fonts/Metropolis/Metropolis-Regular.otf");
}
@font-face {
  font-family: $metropolis-medium;
  src: url("./Assets/Fonts/Metropolis/Metropolis-Medium.otf");
}
@font-face {
  font-family: $metropolis-semiBold;
  src: url("./Assets/Fonts/Metropolis/Metropolis-SemiBold.otf");
}
@font-face {
  font-family: $metropolis-bold;
  src: url("./Assets/Fonts/Metropolis/Metropolis-Bold.otf");
}

html {
  scroll-behavior: smooth;
}
::selection {
  background-color: $primary-color;
  color: $white-color;
}

body {
  margin: 0;
  padding: 0;
  font-family: $metropolis-medium;
}
.remove-a-tag-css {
  text-decoration: none !important;
  color: $primary-color;
  font-family: $lato;
  font-weight: 900;
}
.container {
  max-width: 1290px;
  margin: auto;
  padding: 0 16px;
  @include xl {
    max-width: 1024px;
  }
  @include lg {
    max-width: 100%;
  }
}
.primary-btn {
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  &:before {
    position: absolute;
    content: "";
    background-color: $minimal-color;
    transition: 0.3s ease-out;
    top: 0;
    bottom: 0;
    left: 1px;
    height: 100%;
    width: 100%;
  }
  &::after {
    position: absolute;
    content: "";
  }
  span {
    padding: 13px 0;
    color: rgb(255, 255, 255);
    border: 1px solid $minimal-color;
    transition: 0.2s 0.1s;
    position: relative;
    display: inline-block;
    font-size: 18px;
    font-family: $lato;
    font-weight: 900;
    top: 0;
    left: 0;
    width: 100%;
    letter-spacing: 1px;
    @include xl {
      font-size: 16px;
    }
    @include lg {
      font-size: 14px;
    }
  }
  &:hover {
    &::before {
      width: 0%;
    }
    span {
      color: $minimal-color;
    }
  }
}

h1 {
  font-family: $lato;
  font-size: 48px;
  font-weight: 900;
  line-height: 57.6px;
  letter-spacing: 1px;
  @include md {
    font-size: 24px;
    line-height: 32px;
  }
  @include sm {
    font-size: 24px;
    line-height: 26.11px;
  }
}

h2 {
  font-family: $lato;
  font-size: 40px;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: 1px;
  @include lg {
    font-size: 30px;
    line-height: 38px;
  }
  @include md {
    font-size: 24px;
    line-height: 30px;
  }
}

h3 {
  font-family: $lato;
  font-size: 36px;
  font-weight: 900;
  line-height: 43.2px;
  letter-spacing: 1px;
  @include lg {
    font-size: 30px;
    line-height: 38px;
  }
  @include md {
    font-size: 20px;
    line-height: 30px;
  }
}

h4 {
  font-family: $lato;
  font-size: 32px;
  font-weight: 900;
  line-height: 38.4px;
  letter-spacing: 1px;
  @include lg {
    font-size: 24px;
    line-height: 32px;
  }
  @include md {
    font-size: 18px;
    line-height: 25px;
  }
}

h5 {
  font-family: $lato;
  font-size: 24px;
  font-weight: 900;
  line-height: 28.8px;
  letter-spacing: 1px;
  @include md {
    font-size: 18px;
    line-height: 20px;
  }
}

h6 {
  font-family: $lato;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 1px;
  @include lg {
    font-size: 18px;
    line-height: 22px;
  }
  @include md {
    font-size: 16px;
    line-height: 20px;
  }
}

.Toastify__progress-bar--success {
  background: $primary-color !important;
}

.Toastify__toast--success svg {
  fill: $primary-color !important;
}

@import "../../Assets//Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.blogs {
  margin-bottom: 140px;
  margin-top: 140px;
  height: 100%;
  @include md {
    margin-bottom: 72px;
    margin-top: 72px;
  }
  .container {
    .blog-container {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      @include md {
        flex-direction: column-reverse;
      }
      .leftpart {
        width: 50%;
        padding-top: 50%; /* This makes the height equal to the width */
        background-color: lightblue;
        position: relative;
        @include md {
          width: 100%;
          padding-top: 80%;
        }
        .slider-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .slick-list,
        .slick-track {
          height: 100%;
        }
        .blog {
          // position: relative;
          width: 100%;
          height: 100%;
          .slick-slide > div {
            height: 100%;
          }
          .slick-slide {
            height: 100%;

            &.slick-active {
              .blog-content {
                .blog-description {
                  h5 {
                    transform: translateY(0);
                  }
                  p {
                    transform: translateY(0);
                  }
                }
              }
            }
          }
          .blog-content {
            position: relative;
            display: block !important;
            height: 100%;
            .blog-img {
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .shadow {
              position: absolute;
              background: linear-gradient(
                180deg,
                rgba(16, 63, 87, 0) 0%,
                #103f57 100%
              );
              height: 70%;
              width: 100%;
              bottom: 0;
            }
            .blog-description {
              position: absolute;
              padding-left: 22px;
              padding-right: 22px;
              bottom: 40px;
              @include sm {
                padding-left: 16px;
                padding-right: 16px;
              }
              h5 {
                color: $white-color;
                margin-bottom: 4px;
                transition: 1s;
                transition-delay: 1.5s;
                transform: translateY(20px);
                @include lg {
                  font-size: 20px;
                }
                @include md {
                  font-size: 24px;
                }
                @include sm {
                  font-size: 16px;
                }
              }
              p {
                color: $white-color;
                font-family: $metropolis-regular;
                font-size: 16px;
                font-weight: 400;
                line-height: 28.8px;
                text-align: left;
                margin-top: 0px;
                margin-bottom: 0px;
                transition: 1.3s;
                transition-delay: 1.5s;
                transform: translateY(20px);
                .mobile-screen {
                  display: none;
                }
                @include lg {
                  font-size: 14px;
                  line-height: 22px;
                }
                @include md {
                  font-size: 16px;
                  line-height: 28.8px;
                }
                @include sm {
                  font-size: 12px;
                  line-height: 18.24px;
                  .large-screen {
                    display: none;
                  }
                  .mobile-screen {
                    display: initial;
                  }
                }
                .arrow-btn {
                  color: $minimal-color;
                  background: transparent;
                  line-height: 28.8px;
                  float: right;
                  margin-right: 9px;
                  outline: none;
                  border: none;
                  cursor: pointer;
                  @include lg {
                    line-height: 22px;
                  }
                  @include md {
                    line-height: 28.8px;
                  }
                  @include sm {
                    line-height: 18.24px;
                    svg {
                      width: 14.33px;
                      height: 8.77px;
                    }
                  }
                  span {
                    font-family: $metropolis-regular;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 16px;
                    margin-right: 6px;
                    @include md {
                      font-size: 14px;
                    }
                    @include sm {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
          .slick-dots {
            position: absolute;
            bottom: 16px;
            li {
              &.slick-active {
                width: 48px;
                button {
                  width: 100%;
                  &::before {
                    width: 100%;
                    border-radius: 8px;
                    background-color: $minimal-color;
                    transition: 0.4s;
                  }
                }
              }
              button {
                &::before {
                  content: "";
                  opacity: 1;
                  border-radius: 50%;
                  width: 12px;
                  height: 12px;
                  border: 1px solid $minimal-color;
                }
              }
            }
          }
        }
      }
      .rightpart {
        width: 50%;
        background: $primary-light-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;

        @include md {
          width: calc(100% - 80px);
          padding-left: 50px;
          padding-right: 30px;
          padding-top: 100px;
          padding-bottom: 80px;
        }
        @include sm {
          width: calc(100% - 54px);
          padding-left: 16px;
          padding-right: 38px;
          padding-bottom: 50px;
        }
        .right-part-content {
          max-width: 75%;
          margin: 0 auto;
          h4 {
            color: $primary-color;
            margin-top: 0px;
            margin-bottom: 14px;
          }
          p {
            font-family: $metropolis-light;
            color: $primary-medium-color;
            font-size: 30px;
            font-weight: 400;
            line-height: 45.6px;
            text-align: left;
            margin-top: 0px;
            @include lg {
              font-size: 22px;
              line-height: 35px;
            }
            @include md {
              margin-bottom: 26px;
            }
            @include sm {
              font-size: 18px;
              line-height: 32.4px;
            }
          }
        }
      }
      .img-corner {
        position: absolute;
        right: 0;
        top: -60px;
        @include lg {
          height: 150px;
          width: 150px;
          top: -50px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        @include md {
          height: 102px;
          width: 102px;
          top: -33px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .blog-title-link {
      text-decoration: none !important;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
}

@import "../../Assets/Scss/Variable.scss";
@import "../../Assets/Scss/Mixins.scss";

.privacy-service {
  margin-bottom: 140px;
  @include md {
    margin-bottom: 72px;
  }
  h2,
  h4 {
    color: $primary-color;
    font-size: 28px;
    line-height: 30px;
  }

  p {
    color: $primary-color;
    font-family: $metropolis-regular;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
    @include lg {
      font-size: 16px;
      line-height: 28px;
    }
    @include md {
      font-size: 12px;
      line-height: 20px;
    }
    @include sm {
      font-size: 10px;
      line-height: 20px;
    }
    span {
      font-family: $metropolis-bold;
      font-weight: 600;
    }
    a {
      color: $primary-color;
      text-decoration: none;
    }
    .link {
      font-family: $metropolis-regular;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      svg {
        margin: -5px 0px;
      }
      @include lg {
        font-size: 16px;
        line-height: 28px;
        svg {
          margin: -4px 0px;
        }
      }
      @include md {
        font-size: 12px;
        line-height: 20px;
        svg {
          margin: -3px 0px;
        }
      }
      @include sm {
        font-size: 10px;
        line-height: 20px;
        svg {
          margin: -2px 0px;
        }
      }
    }
  }
  h3 {
    color: $primary-color;
    margin-bottom: 12px;
    font-size: 30px;
  }
  h4 {
    color: $primary-color;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .space {
    margin-top: 20px;
  }
}
